:root {
    --contentCarousel-pager-color: var(--g-color-brand-primary);
}

.pager {
    padding: 4rem 0 0;
    display: flex;
    justify-content: center;
    gap: 0 0.8rem;

    &__container {
        display: inline-block;
        font-size: 0;
    }

    &__button {
        background: var(--contentCarousel-pager-color);
        border: 0;
        height: 0.4rem;
        opacity: 0.3;
        outline: 0;
        transition: 0.3s ease-in-out opacity;
        width: 5.6rem;

        &:hover {
            opacity: 0.6;
        }
    }

    &__button.is-active {
        opacity: 1;
    }
}
