.standings {
    $this: &;
    &__table-container {
        position: relative;
    }

    &__comp-logo {
        max-height: 4rem;
    }
}
