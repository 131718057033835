/* stylelint-disable selector-max-attribute, selector-no-qualifying-type */

/* Block List */

/* 
  Iterates up to the set limit, generating block list classes for each integer, 
  using that integer to determine the width of each card 
*/
@mixin generate-classes($breakpoint: null, $limit: 12) {
    $i: 1;

    @while $i <= $limit {
        $class: '.block-list-#{$i}';
        @if $breakpoint {
            $class: '#{$class}-#{$breakpoint}';
        }

        #{$class} > li {
            width: calc(
                ((100% + var(--card-gutter)) / #{$i}) - var(--card-gutter)
            );
        }

        $i: $i + 1;
    }
}

/* 
  Iterates up to the set limit, generating block list classes for each integer, 
  using that integer to determine the width of each card 
*/
@mixin generate-classes-mobile($breakpoint: null, $limit: 12) {
    $i: 1;

    @while $i <= $limit {
        $class: '.block-list-#{$i}';
        @if $breakpoint {
            $class: '#{$class}-#{$breakpoint}';
        }

        #{$class} > li {
            width: calc((100% / #{$i}) - (4.8rem / #{$i}));
        }

        $i: $i + 1;
    }
}

[class*='block-list'] {
    display: flex;
    transition: transform ease-in-out 0.3s;
    will-change: transform;

    > li {
        display: flex;
        flex-shrink: 0;
        transition: 0.3s ease-in-out opacity;
        margin-inline-end: var(--card-gutter);

        &.is-inactive {
            opacity: 0.4;
            pointer-events: none;
        }

        &:last-of-type {
            margin-inline-end: 0;
        }
    }
}

@include generate-classes;

@media (max-width: $break-wide) {
    @include generate-classes('wide', 6);
}

@media (max-width: $break-desktop) {
    @include generate-classes('desktop', 4);
}

@media (max-width: $break-tablet) {
    @include generate-classes('tablet', 4);
}

// From phablet down, enter 'manual-scroll' mode for forcing cards to 44% width
@media (max-width: $break-phablet) {
    @include generate-classes-mobile('phablet', 3);

    [class*='block-list'] {
        $gutter: 0.8rem;
        overflow: auto;
        scroll-behavior: smooth;
        margin-left: -$gutter;
        margin-right: -$gutter;

        > li {
            margin-bottom: 2.8rem; // margin-bottom needed at mobile to ensure global box-shadow is not clipped (when applied)
            margin-inline-end: var(--card-gutter);

            &.is-inactive {
                opacity: 1;
                pointer-events: all;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        // buffer to create illusion of gutter at start and end of manual scroll carousel
        &::before,
        &::after {
            height: 1rem;
            width: $gutter;
            display: block;
            flex-shrink: 0;
        }

        &:first-of-type::before {
            content: '';
        }

        &:last-of-type::after {
            content: '';
        }
    }
}
/* stylelint-enable */
