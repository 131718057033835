.w-mc-tabs {

    .page-nav-wrapper {
        margin-bottom: 3rem;
    }

    .page-nav-wrapper__container {
        justify-content: center;
    }
}
