// Styles for: Widget header
// Notes: Widget header style for all devices

// CONFIG

:root {
    --widgetHeader-title-color: var(--g-typography-color-widget-heading);
    --widgetHeader-ctaText-color-default: var(--g-color-system-light);
    --widgetHeader-ctaText-color-hover: var(--g-color-system-light);
    --widgetHeader-cta-backgroundColor-default: var(--g-color-brand-primary);
    --widgetHeader-cta-backgroundColor-hover: var(--g-color-brand-primary);
}

.o-widget-header {
    align-items: center;
    display: flex;
    position: relative;
    justify-content: flex-end;
    margin-bottom: 0;

    // :has(*) pseudo selector with * checks if there are nested elements,
    // in that case add a margin bottom
    /* stylelint-disable-next-line selector-max-universal */
    &:has(*) {
        margin-bottom: 4rem;
    }

    &__title {
        @include heading-M;
        color: var(--widgetHeader-title-color);

        &-wrapper {
            flex-grow: 1;
        }
    }

    &__brand-bar {
        margin-top: 1.6rem;
    }

    &__reset-filter {
        align-items: center;
        display: flex;
        font-size: 1.4rem;
        font-weight: $bold;
        margin: 0 2.4rem 0 auto;
        outline: 0;
        color: var(--filter-resetTextColour);
    }

    &__reset-filter-icon {
        display: inline-block;
        height: 1.4rem;
        margin-right: 0.8rem;
        width: 1.4rem;
        fill: var(--filter-resetTextColour);
    }

    @media (max-width: $break-phablet) {
        /* stylelint-disable-next-line selector-max-universal */
        &:has(*) {
            margin-bottom: $spacing-m;
        }

        &__brand-bar {
            margin-top: 0.8rem;
        }

        // Override to hide CTA text at mobile for widget header to conserve horizontal space
        .o-cta {
            span {
                display: none;
            }
        }
    }
}
