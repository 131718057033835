.related-content {
    $this: &;
    max-width: 100rem;
    margin: 0 auto;

    &--carousel {
        @include wrapper;
        position: relative;

        &::after {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            z-index: $level-one;
        }

        #{$this} {
            &__content {
                display: flex;
                transition: transform .25s ease-in-out;
            }
        }

        .related-content-thumb {
            flex: 0 0 calc((100% / 3) - 2rem);

            @media(min-width: $break-phablet) {
                &:not(.is-active) {
                    pointer-events: none;
                    opacity: .6;
                }
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    &__title {
        font-size: 3rem;
        line-height: 4rem;
        margin: 2.4rem 0 2rem;
    }

    @media(max-width: $break-desktop) {
        &__content {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media(max-width: $break-tablet) {
        padding: 0 1rem;
    }

    @media(max-width: $break-phablet) {
        &--carousel {
            #{$this} {
                &__content {
                    flex-direction: row;
                    overflow: auto;
                }
            }

            .related-content-thumb {
                min-width: 32rem;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
        }

        &__title {
            font-size: 2rem;
            line-height: 3rem;
        }
    }
}
