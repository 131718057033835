:root {
    --score-bg-colour: var(--g-color-brand-primary);
    --live-colour: var(--g-color-brand-accent);
    --match-border-colour: var(--g-color-brand-primary);
    --cta-colour: var(--g-color-brand-primary);
}

$score-bg-colour:  var(--g-color-brand-primary);

.o-match-card {
    $this: &;
    align-items: center;
    border:2px solid transparent;
    border-top: 2px solid var(--match-border-colour);
    transition: all ease-out .4s;
    cursor: pointer;
    box-shadow: .2rem .8rem 2rem rgba($black, .1);
    display: flex;
    gap: 1.6rem;
    padding-right: 1.6rem;

    &:hover {
        border-color: var(--match-border-colour);
    }

    &__match-date {
        display: block;
        width: calc(100% - 4.8rem);
        padding-top: 1.6rem;
        margin: 0 auto;
        text-align: center;
    }

    &--upcoming {
        cursor: auto;
    }

    &__container {
        align-items: center;
        display: flex;
        gap: 1.6rem;
    }

    &__match {
        @include link-reset;
        display: block;
        padding: 2rem 0 2rem 1.6rem;
        width: 100%;
    }

    &__broadcaster {
        flex-shrink: 0;
        width: 6.4rem;

        img {
            display: block;
        }
    }

    &__ctas {
        flex-shrink: 0;
        width: 14rem;
    }

    .o-cta-primary {
        background: var(--cta-colour);
        width: 100%;
    }

    &__team-name {
        @include heading-S;
        text-align: right;

        &--short {
            display: none;
        }
    }

    &__competition {
        align-items: center;
        display: flex;
        flex-shrink: 0;
        justify-content: flex-end;
        width: 8.4rem;
    }

    &__competition-logo {
        display: block;
        height: auto;
        width: 100%;
    }

    &__score-container {
        align-items: center;
        display: grid;
        gap: 1.6rem;
        grid-template-columns: 1fr 9rem 1fr;
        margin: 0 auto;

        p {
            display: flex;
            margin: 1rem;
            justify-content: center;
            align-items: center;
        }
    }

    &__team-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-transform: uppercase;
        gap: 1.6rem;

        &--away {
            justify-content: flex-start;

            #{$this}__team-name {
                text-align: left;
            }
        }
    }

    &__team-logo {
        width: 6rem;
        height: 6rem;
        display: block;
    }

    &__score {
        display: flex;
        gap: .4rem;
        justify-content: center;
        align-items: center;

        &--live {
            --score-bg-colour: var(--live-colour);
        }

        span {
            @include heading-S;
            background-color: var(--score-bg-colour);
            color: var(--c-color-white);
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--home {
            span:nth-of-type(2) {
                background-color: color-mix(in srgb, var(--score-bg-colour) 10%, white);
                color: var(--c-color-black);
            }
        }

        &--away {
            span:nth-of-type(1) {
                background-color: color-mix(in srgb, var(--score-bg-colour) 10%, white);
                color: var(--c-color-black);
            }
        }

    }

    &__kick-off {
        align-items: center;
        background-color: color-mix(in srgb, var(--score-bg-colour) 10%, white);
        display: flex;
        flex-direction: column;
        font-size: var(--c-text-medium-desktop);
        font-weight: $bold;
        justify-content: center;
        letter-spacing: -0.05rem;
        padding: 1rem;
        position: relative;

        &::after {
            content: attr(data-ko-time);
            font-size: 1rem;
            font-weight: $regular;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }

    &__meta {
        flex-shrink: 0;
        width: 12rem;
    }

    &__date {
        font-size: var(--c-text-small-desktop);
        font-weight: $bold;
        line-height: 1.2;
    }

    &__stadium-name {
        font-size: var(--c-text-xsmall-desktop);
        line-height: 1.2;
    }

    & .o-mc-gameweek-matches__match-scorebox {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    &__live-label,
    &__time {
        position: absolute;
        font-size: var(--c-text-small-desktop);
        font-weight: $bold;
    }

    &__live-label {
        top: -2.2rem;
        text-transform: uppercase;
        color: var(--live-colour);
    }

    &__time {
        bottom: -2.2rem;
        color: var(--live-colour);
    }

    @media(max-width: $break-desktop) {
        display: block;
        padding-right: 0;

        &__match {
            margin: 1.6rem 0;
            padding: 0;
        }

        &__container {
            flex-direction: column;
        }

        &__competition {
            order: 0;
            width: auto;
        }

        &__competition-logo {
            height: 4rem;
        }

        &__score-container {
            order: 1;
        }

        &__meta {
            order: 2;
            width: auto;
        }

        &__date {
            display: inline-block;
            font-size: var(--c-text-xsmall-desktop);
            margin-right: .5rem;
        }

        &__stadium-name {
            display: inline-block;
        }

        &__broadcaster {
            margin: 1.6rem auto;
        }
        
        &__attendance {
            text-align: center;
        }

        &__ctas {
            width: 100%;
        }
    }

    @media(max-width: $break-tablet) {

        &__competition-logo {
            height: 3rem;
        }

        &__score-container {
            gap: .8rem;
            grid-template-columns: 1fr 6rem 1fr;
        }

        &__team-container {
            gap: .6rem;
        }

        &__team-logo {
            width: 3.6rem;
            height: 3.6rem;
        }

        &__score {
            gap: .2rem;

            span {
                width: 2.8rem;
                height: 2.8rem;
            }
        }

        &__scorebox {
            gap: 1.6rem;
        }

        &__kick-off {
            padding: .8rem;
        }
    }

    @media(max-width: $break-phablet) {

        &__container {
            position: relative;
        }

        .o-mc-gameweek-matches__match-scorebox {
            position: unset;
        }

        &__live-label {
            top: -.5rem;
            left: 1rem;
        }

        &__time {
            top: -.5rem;
            right: 0;
        }
    }
}
