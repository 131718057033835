.o-mc-timeline {
    $this: &;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $wrapper-width;
    margin: 0 auto;
    padding: 0 2.4rem;
    overflow: visible;
    animation: fade .4s ease-in-out;
    animation-delay: .4s;
    animation-fill-mode: backwards;
    position: relative;
    z-index: $level-four;

    &::before {
        content: '';
        width: 100%;
        max-width: 137.6rem;
        margin: 0 auto;
        height: .2rem;
        background-color: rgba($white, 0.25);
        position: absolute;
        left: 0;
        z-index: -1;
        top: 50%;
    }

    &__teams {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.6rem;
    }

    &__team-logo {
        width: 2.8rem;
        height: 2.8rem;

        &--away {
            margin-top: .4rem;
        }
    }

    &__badge {
        width: 100%;
        height: 100%;
    }

    &__events {
        display: flex;
        align-items: center;
        flex: 1;
        position: relative;
    }

    &__event {
        align-self: stretch;
        position: relative;
        display: flex;
        align-items: center;
        flex: 1 0 auto;
        flex-direction: column;
        justify-content: center;
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-weight: $bold;

        &--blank,
        &--completed {
            width: 0;
            overflow: hidden;
            color: transparent;
        }
    }

    &__icon {
        cursor: pointer;
        display: block;
        width: 1.4rem;
        height: 1.4rem;
        margin: 0.4rem auto;

        &:not(#{&}--dummy) {

            &:focus,
            &:hover {
                #{$this}__tooltip {
                    display: block;
                    opacity: 1;
                }
            }
        }

        &--card-yellow {
            .icon {
                fill: $yellow;
            }
        }

        &--card-red {
            .icon {
                fill: var(--mc-own-goal-icon-color);
            }
        }

        &--football,
        &--football-og {
            .icon {
                width: 1.8rem;
                height: 1.8rem;
                fill: $white;
            }
        }

        &--football-og {
            .icon {
                fill: var(--mc-red-card-icon-color);
            }
        }
    }

    &__icons {
        margin-left: -.8rem;

        &--home {
            transform: translateY(-.8rem);
        }
    }

    &__tooltip {
        display: none;
        opacity: 0;
        position: absolute;
        transition: opacity .3s ease-out;
        text-align: center;
        z-index: 80;
        padding: 0;
        pointer-events: none;
        border-radius: 0.4rem;
        overflow: hidden;
        width: 23.8rem;
        height: 8.6rem;
        transform: translate(9.2rem, -11.4rem);
        right: -1.2rem;
        border: .1rem solid rgb(255 255 255 / 24%);
        background-color: rgb(19 25 36 / 8%);
        backdrop-filter: blur(20px);
        box-shadow: 0 .2rem .4rem 0 rgba($black, 0.25);

        &--S {
            height: 12.6rem;
            transform: translate(9.2rem, -15.4rem);
        }

        &--left {
            transform: translate(-1.4rem, -11.4rem);

            &#{$this}__tooltip--S {
                transform: translate(-1.4rem, -15.4rem);
            }
        }

        &--right {
            transform: translate(20.2rem, -11.4rem);

            &#{$this}__tooltip--S {
                transform: translate(20.2rem, -15.4rem);
            }
        }

        &-info {
            display: flex;
            align-items: center;
            gap: .4rem;

            .icon {
                width: 1.2rem;
                height: 1.2rem;
                margin-bottom: .2rem;
            }
        }

        &-player-img {
            width: 3.2rem;
            height: 3.2rem;
        }

        &-header {
            padding: .6rem .8rem;
            display: flex;
            justify-content: space-between;
            font-size: 1.2rem;
            line-height: 1.8rem;
            font-weight: $bold;
            text-transform: uppercase;
            border-bottom: 0.5px solid rgb(255 255 255 / 24%);
        }

        &-body {
            padding: .8rem .8rem .8rem 1rem;
            display: flex;
            align-items: center;
            gap: .8rem;
        }

        &-player-info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        &-player-name {
            font-size: 1.2rem;
            line-height: 1.8rem;
            font-weight: $bold;
            text-transform: uppercase;
        }

        &-assist {
            font-size: 1rem;
            line-height: 1.4rem;

            &-name {
                text-transform: uppercase;
            }
        }

        &-scorebox {
            display: flex;
            gap: .2rem;
        }

        &-score {
            font-size: 1.2rem;
            line-height: 1.8rem;
            font-weight: $bold;
            display: flex;
            gap: .1rem;

            span {
                background-color: var(--mc-score-box-color);
                color: var(--live-color-text-override, var(--g-color-system-white));
                width: 1.6rem;
                height: 1.6rem;
            }
        }

        &-substitution {
            display: flex;
            flex-direction: column;
            gap: .8rem;
            width: 100%;

            &-player {
                display: flex;
                align-items: center;
                gap: .8rem;
            }
        }

        &-sub-event {
            font-size: 1rem;
            line-height: 1.4rem;
            font-weight: $bold;
            color: var(--sub-event-color);
            display: flex;
            align-items: center;
            gap: .2rem;
            text-transform: uppercase;

            .icon {
                width: .7rem;
                height: .6rem;
                margin-bottom: .2rem;
            }

            &--on {
                --sub-event-color: #6fbe44;
            }

            &--off {
                --sub-event-color: #ea6759;
            }
        }
    }

    @media(max-width: $break-tablet) {
        display: none;
    }
}