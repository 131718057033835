:root {
    --score-bg-colour: var(--g-color-brand-primary);
}

.match {
    $this: &;

    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: var(--c-color-white);
    box-shadow: 0.2rem 0.8rem 2rem rgba(0, 0, 0, 0.1);
    padding: 2rem 1.6rem;
    position: relative;
    transition: all ease-out 0.4s;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__link {
        inset: 0;
        position: absolute;
    }

    &__title {
        @include heading-S;
    }

    &__team-type {
        font-size: 1.5rem;
        font-weight: $bold;
        margin: 1.2rem 0;
    }

    &__info,
    &__stadium-info {
        display: flex;
        align-items: center;
    }

    &__info {
        flex-wrap: wrap;
        font-size: var(--c-text-medium-desktop);
    }

    &__competition-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .8rem;
    }

    &__comp-icon {
        width: auto;
        height: 5rem;
    }

    &__stadium-info {
        gap: .4rem;
    }

    &__kickoff {
        display: flex;
        align-items: center;
        gap: .4rem;
        margin-right: .4rem;
        font-weight: $bold;

        &::after {
            content: '';
            display: block;
            width: .4rem;
            height: .4rem;
            background-color: $black;
            border-radius: 50%;
        }
    }

    &__kickoff-time {
        display: flex;
        align-items: center;
        padding: .8rem;
        background-color: color-mix(in srgb, var(--score-bg-colour) 10%, white);
        font-size: var(--c-text-medium-desktop);
        font-weight: bold;
    }

    &__match-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 2.4rem 0;
    }

    &__team {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &__team-badge {
        width: 2.5rem;
        height: 2.5rem;
    }

    &__team-name {
        @include heading-S;
    }

    &__score-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .6rem;

        &--home-win #{$this}__score:nth-child(1),
        &--away-win #{$this}__score:nth-child(2) {
            background-color: var(--score-bg-colour);
            color: var(--c-color-white);
        }
    }

    &__score {
        width: 3.4rem;
        padding: .8rem 0;
        background-color: color-mix(in srgb, var(--score-bg-colour) 10%, white);
        font-size: var(--c-text-medium-desktop);
        font-weight: bold;
        text-align: center;
    }

    &__ctas {
        border-top: 1px solid rgba($grey-400, .5);
        display: flex;
        justify-content: center;
        margin-top: auto;
        padding: 2rem 1.6rem 0;
    }

    @media(max-width: $break-desktop) {

        &__team-type {
            font-size: 1.2rem;
            margin: 0.8rem 0;
        }

        &__team-badge {
            width: 3rem;
            height: 3rem;
        }

        &__comp-icon {
            height: 3rem;
        }

        &__ctas {
            margin-top: 2rem;
        }
    }

    @media(max-width: $break-phablet) {

        &__title {
            font-size: 2.5rem;
        }

        &__team-type {
            font-size: 1rem;
        }

        &__team-name {
            font-size: 2rem;
        }

        &__kickoff-hour {
            font-size: 2rem;
        }
    }
}
