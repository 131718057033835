.o-gameweek {
    $this: &;
    margin-top: 4.8rem;

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: .8rem;
        margin-bottom: .8rem;
        flex-wrap: wrap
    }

    &__date-wrapper {
        margin-bottom: 2.4rem
    }

    &__matches-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
    }

    @media(max-width: $break-tablet) {

        &__matches-wrapper {
            gap: 1.2rem;
        }
    }

    @media(max-width: $break-phablet) {
        margin-top: 3.2rem;
    }
}
