// CONFIG

$placeholder-icon-fill: rgba($grey-400, 0.2);

:root {
    --o-placeholder-root-backgroundColor: var(--g-color-system-dark);
    --o-placeholder-icon-fill: #{$placeholder-icon-fill};
}

.o-placeholder {
    position: relative;
    height: 100%;
    background-color: var(--o-placeholder-root-backgroundColor);

    &__svg {
        &.icon {
            position: absolute;
            width: auto;
            max-width: 5rem;
            height: auto;
            margin: auto;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            fill: var(--o-placeholder-icon-fill);
        }
    }
}
