// Styles for: Arrow link
// Notes: Generic arrow link style

// stylelint-disable selector-max-specificity, selector-max-type, selector-no-qualifying-type, selector-max-universal

// ARROW LINK

%arrow-link,
.arrow-link,
.o-arrow-link {
    @include arrow-link;
    @include text-button;
    display: inline-flex;
    align-items: center;
    position: relative;
    border: none;
    color: var(--g-button-ghost-color);
    text-align: left;
    text-decoration: none;
    white-space: nowrap;
    user-select: none;
    touch-action: manipulation;
    gap: 0.8rem;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    svg {
        width: 1rem;
        height: 1rem;
        flex-shrink: 0;
        fill: var(--g-button-ghost-color);
    }

    @include hover {
        text-decoration: underline;
    }
}

// ARROW LINK PRIMARY

@mixin arrow-link-primary {
    @extend %arrow-link;
    color: var(--g-button-ghost-color);

    svg {
        fill: var(--g-button-ghost-color);
    }

    @include hover {
        color: var(--g-button-ghost-color);
        text-decoration: underline;
    }
}

@mixin arrow-link-parent-hover {
    %arrow-link,
    .arrow-link,
    .o-arrow-link {
        text-decoration: underline;
    }
}

.arrow-link-primary,
.o-arrow-link-primary {
    @include arrow-link-primary;
}
