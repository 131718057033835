// Styles for: "Football: Player Stats"

:root {
    --football-player-hero-bg-stat-color: var(--g-color-system-dark);
}
.player-stats {
    $this: &;
    padding-bottom: 4rem;
    border-bottom: .1rem solid $border-color-inverted-color;

    &__title {
        @include heading-S;
        text-transform: uppercase;
        margin-bottom: 1.6rem;
    }

    &__stats-container {
        display: flex;
        gap: 1.6rem;
        flex-wrap: wrap;
    }

    &__stat {
        flex: 1;
        padding: 1.6rem;
        color: $white;
        background-color: var(--football-player-hero-bg-stat-color);
        z-index: $level-one;

        &-title {
            @include heading-XXS;
            margin-bottom: 1.2rem;
            display: flex;
            align-items: center;
            gap: .6rem;
            white-space: nowrap;
        }

        &-icon {
            width: 2.4rem;
            height: 2.4rem;
            fill: $white;

            &--yellow {
                fill: $yellow;
            }

            &--red {
                fill: $red;
            }
        }

        &-value {
            @include heading-L;
            line-height: 1;
            display: flex;
            align-items: baseline;
        }

        &-sub {
            @include heading-S;
            bottom: 0;
        }
    }

    @media (max-width: $break-desktop) {
        max-width: calc(100vw - 3.2rem);
        margin-left: 1.6rem;

        &__stats-container {
            overflow-x: auto;
        }
    }

    @media (max-width: $break-phablet) {
        padding: 1.6rem 0;
        order: 4;

        &__title {
            @include text-copy;
        }

        &__stats-container {
            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__stat {
            min-width: 16.2rem;

            &-title {
                @include text-copy;
            }
        }
    }
}
