// Styles for: CTA
// Notes: Generic CTA style

// stylelint-disable selector-max-specificity, selector-max-type, selector-no-qualifying-type, selector-max-universal

// BUTTON

button {
    background: transparent;
    border: none;
    padding: 0;
}

%cta,
.cta,
.o-cta {
    @include cta;
    @include text-button;
    height: 4.4rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    user-select: none;
    color: var(--g-button-primary-color);
    background: var(--g-button-primary-background);
    border: 0.2rem solid var(--g-button-primary-border-color);
    box-shadow: var(--g-layout-box-shadow);
    border-radius: var(--g-button-border-radius);
    padding: var(--g-layout-button-spacing);
    touch-action: manipulation;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    gap: 0.8rem;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        z-index: $level-one;
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }

    svg {
        width: 1.1rem;
        height: 1.1rem;
        fill: var(--g-button-primary-color);
        flex-shrink: 0;
        position: relative;
        z-index: $level-one;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        background-color: var(--g-button-primary-hover-overlay);
        transition: opacity 0.25s;
    }

    @include hover {
        color: var(--g-button-primary-color);
        background: var(--g-button-primary-background);

        &::after {
            opacity: 1;
        }
    }

    // Halve border radius at mobile
    @media (max-width: $break-phablet) {
        border-radius: calc(#{var(--g-button-border-radius)} / 2);
    }
}

// BUTTON PRIMARY

@mixin cta-primary {
    @extend %cta;
    color: var(--g-button-primary-color);
    background: var(--g-button-primary-background);
    border: 0.2rem solid var(--g-button-primary-border-color);

    svg {
        fill: var(--g-button-primary-color);
    }

    &::after {
        background-color: var(--g-button-primary-hover-overlay);
    }

    @include hover {
        color: var(--g-button-primary-color);
        background: var(--g-button-primary-background);
        border-color: var(--g-button-primary-border-color);
    }
}

.cta-primary,
.o-cta-primary {
    @include cta-primary;
}

// BUTTON SECONDARY

@mixin cta-secondary {
    @extend %cta;
    color: var(--g-button-secondary-color);
    background: var(--g-button-secondary-background);
    border: 0.2rem solid var(--g-button-secondary-border-color);

    svg {
        fill: var(--g-button-secondary-color);
    }

    &::after {
        background-color: var(--g-button-secondary-hover-overlay);
    }

    @include hover {
        color: var(--g-button-secondary-color);
        background: var(--g-button-secondary-background);
        border-color: var(--g-button-secondary-border-color);
    }
}

.cta-secondary,
.o-cta-secondary {
    @include cta-secondary;
}
