:root {
    --contentCarousel-arrow-color: var(--g-color-brand-primary);
}

// This class is for the element that wraps a list of cards and has a nested nav button controls element
// It allows the centre-positioning of this element
// TODO: Soon we will move this into the widget header so we can deprecate the positioning logic here (and the wrapper element)
.content-slider {
    $this: &;
    position: relative;

    &__nav-button-container {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &__nav-button {
        background-color: var(--contentCarousel-arrow-color);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 4.8rem;
        height: 4.8rem;
        outline: 0;
        border: 0;
        overflow: hidden;
        position: relative;
        text-indent: -999px;
        transition: 0.3s ease-in-out opacity, 0.3s ease-in-out filter;

        &-icon {
            fill: $white;
            width: 1.4rem;
            height: 1.4rem;
            margin-top: 2px;
        }

        &:hover {
            filter: brightness(0.8);
        }

        &--next {
            background-image: url($img-dir + '/elements/slider-arrow-right.svg');
        }

        &.faded {
            opacity: 0.4;
            pointer-events: none;
        }
    }
}
