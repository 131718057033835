:root {
    --wins-background-colour: var(--g-color-brand-primary);
}

.w-mc-head-to-head {
    $this: &;

    &__matches-since {
        @include heading-XS;
        margin-bottom: 2.4rem;
        text-align: center;
    }

    &__outcomes {
        display: flex;
        gap: 1.6rem;

        &--reverse {
            flex-direction: row-reverse;
        }
    }

    &__outcome {
        align-items: center;
        aspect-ratio: 1/1;
        background-color: $grey-400;
        display: flex;
        justify-content: center;
        width: calc(1/3 * 100%);

        &--wins {
            background-color: var(--wins-background-colour);
        }
    }

    &__outcome-label {
        color: var(--c-color-white);
        font-size: var(--c-text-small-desktop);
        text-align: center;

        strong {
            display: block;
            font-size: var(--c-title-xlarge-desktop);
        }
    }

    &__bar-header {
        align-items: center;
        display: flex;
        font-size: var(--c-text-small-desktop);
        justify-content: space-between;
        margin: 1.6rem 0 1rem;

        &--reverse {
            flex-direction: row-reverse;
        }
    }
    
    &__bar-value {
        font-weight: 700;
    }

    &__bar {
        background-color: $grey-400;
        display: flex;
        height: .3rem;

        &--reverse {
            flex-direction: row-reverse;
        }
    }

    &__value {
        background-color: var(--wins-background-colour);
    }
}