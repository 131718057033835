:root {
    --widget-background-color: var(--g-color-system-light);
    --border-colour: var(--g-color-brand-primary);
}

.results-and-standings {
    background-color: var(--widget-background-color);
    padding: 5.4rem 3.4rem;

    &__content-wrapper {
        display: flex;
    }

    &__matches-wrapper {
        display: flex;
        flex-basis: calc((100% / 3) * 2);
        gap: 2rem;
        margin-right: 2rem;
    }


    &__standings {
        flex-basis: calc(100% / 3);
        padding: 1.6rem;
        background-color: var(--c-color-white);
        border-top: 2px solid var(--border-colour);
        box-shadow: 0.2rem 0.8rem 2rem rgba(0, 0, 0, 0.1);
    }

    .o-cta-primary {
        background-color: var(--standings-accent-colour);
    }

    .match {
        height: 100%;
        border: 2px solid transparent;
        border-top: 2px solid var(--border-colour);

        &:hover {
            border-color: var(--border-colour);
        }
    }

    &__match-wrapper {
        flex: 1 1;
    }

    @media(max-width: $break-desktop) {
        padding: 5.4rem 1rem 3.4rem;

        &__content-wrapper {
            flex-wrap: wrap;
            gap: 2rem;
        }

        &__matches-wrapper {
            margin-right: 0;
        }

        &__matches-wrapper,
        &__standings {
            flex: 1 1 100%;
        }
    }

    @media(max-width: $break-tablet) {
        &__matches-wrapper {
            flex-direction: column;
            margin: 0;
        }
    }
}
