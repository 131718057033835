.o-mc-player-card {
    $this: &;
    position: relative;
    z-index: $level-two;
    width: 100%;
    height: 100%;

    &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: $level-two;
    }

    &__icon {
        color: var(--g-color-system-white);
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        left: -1.2rem;
        top: 20%;
        background-color: var(--g-color-brand-primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    &__number {
        position: absolute;
        bottom: -.2rem;
        right: 50%;
        transform: translateX(50%);
        color: var(--g-color-system-white);
        font-size: 1.4rem;
        line-height: 2.1rem;
        font-weight: $bold;
        background: var(--g-color-system-dark);
        z-index: $level-three;
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}