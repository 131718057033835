.w-mc-lineups {
    $this: &;

    display: flex;
    gap: 6rem;
    justify-content: center;

    &__team-line-up {
        width: 26rem;
        padding-top: 2.4rem;
        border-top: .1rem solid var(--g-color-stone-30);
        border-top-right-radius: .8rem;
        border-top-left-radius: .8rem;
    }

    &__formation {
        display: flex;
        gap: 1.6rem;
        justify-content: flex-end;
        margin-bottom: 1.6rem;
    }

    &__team-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: .6rem;
    }

    &__team-badge {
        width: 5rem;
    }

    &__team-name {
        @include heading-S;
        text-transform: uppercase;
    }

    &__formation-text {
        @include heading-XS;
        text-transform: uppercase;
    }

    &__position-text,
    &__player {
        margin-bottom: $spacing-s;
    }

    &__position-text {
        @include heading-XS;
        text-align: right;
        text-transform: uppercase;
        padding: 1.2rem;
        background-color: var(--g-color-system-mid);
    }

    &__player {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.8rem;
    }

    &__player-number {
        width: 3rem;
        @include heading-S;
        text-align: right;
    }

    &__player-image {
        width: 4.8rem;
        height: auto;
    }

    &__player-info {
        display: flex;
        flex-direction: column;
        gap: .4rem;
        text-align: right;
    }

    &__player-name {

        &-first {
            font-size: var(--c-text-xsmall-desktop);
        }

        &-last {
            @include heading-XS;
            white-space: nowrap;
        }
    }

    &__player-captain {
        display: flex;
        justify-content: center;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        color: var(--c-color-white);
        background-color: var(--g-color-brand-primary);
        @include heading-XS;
        line-height: 1.4rem;
    }

    .player-nationality {
        display: flex;
        align-items: center;
        gap: .6rem;

        &__flag-icon {
            display: flex;
            width: 2.6rem;
        }

        .CH &__flag-icon {
            height: 1.7rem;
        }
    }

    &__player-events {
        align-items: center;
        display: flex;
        gap: .4rem;
    }

    &__event {
        align-items: center;
        display: flex;
        font-weight: $bold;
        gap: .4rem;

        svg {
            width: 1.4rem;
            height: 1.4rem;
        }

        &--sub {
            border: 1px solid var(--g-color-system-mid);
            border-radius: 1.6rem;
            padding: .3rem .5rem;

            svg {
                width: 1rem;
                height: 1rem;
            }
        }

        &--football-og,
        &--card-red {
            svg {
                fill: $red;
            }
        }

        &--card-yellow {
            svg {
                fill: $yellow;
            }
        }
    }

    &__team-line-up--home {

        .player-nationality {
            flex-direction: row-reverse;
        }
    }

    &__team-line-up--away {

        #{$this}__formation {
            justify-content: flex-start;
        }

        #{$this}__team-info {
            align-items: flex-start;
        }

        #{$this}__player {
            flex-direction: row-reverse;
        }

        #{$this}__position-text,
        #{$this}__player-number,
        #{$this}__player-info {
            text-align: left;
        }
    }

    @media(max-width: $break-phablet) {
        flex-direction: column;
        align-items: center;
        gap: 3rem;

        &__team-line-up {
            width: 46rem;
            max-width: 94vw;
        }

        &__formation,
        &__player {
            justify-content: flex-start;
        }

        &__team-line-up--home {

            #{$this}__formation {
                flex-direction: row-reverse;
                justify-content: flex-end;
            }

            #{$this}__team-info {
                align-items: flex-start;
            }

            #{$this}__position-text,
            #{$this}__player-info {
                text-align: left;
            }

            #{$this}__player {
                flex-direction: row-reverse;
                justify-content: flex-end;
            }
        }

        &__team-line-up--away {

            #{$this}__player {
                justify-content: flex-end;
            }
        }
    }

}
