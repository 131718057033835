// Styles for: Wide media pod
// Notes: Wide media pod style for all devices

// CONFIG

:root {
    --wideMediaPod-root-backgroundColor: var(--g-component-background);
    --wideMediaPod-root-borderRadius: var(--g-layout-border-radius);
    --wideMediaPod-root-border: var(--c-border-none);
    --wideMediaPod-root-boxShadow: var(--g-layout-box-shadow);
    --wideMediaPod-brandBar-background: var(--g-color-brand-primary);
    --wideMediaPod-title-color: var(--g-color-system-dark);
    --wideMediaPod-title-fontSize-desktop: var(--c-title-large-desktop);
    --wideMediaPod-title-fontSize-desktop-large: var(--c-title-xlarge-desktop);
    --wideMediaPod-title-fontSize-mobile: var(--c-title-xlarge-mobile);
    --wideMediaPod-summary-fontSize-desktop: var(--c-text-medium-desktop);
    --wideMediaPod-summary-fontSize-mobile: var(--c-text-medium-mobile);
    --wideMediaPod-summary-color: var(--g-color-system-dark);
    --wideMediaPod-ctaText-color-default: var(--g-color-system-light);
    --wideMediaPod-ctaText-color-hover: var(--g-color-system-light);
    --wideMediaPod-cta-backgroundColor-default: var(--g-color-brand-primary);
    --wideMediaPod-cta-backgroundColor-hover: var(--g-color-brand-primary);
    --wideMediaPod-cta-boxShadow-default: var(--g-layout-box-shadow);
    --wideMediaPod-cta-boxShadow-hover: var(--g-layout-box-shadow);
}

// WIDE MEDIA POD

.o-wide-media-pod {
    $this: &;
    width: 100%;
    border: var(--wideMediaPod-root-border);
    border-radius: var(--wideMediaPod-root-borderRadius);
    overflow: hidden;
    background-color: var(--wideMediaPod-root-backgroundColor);
    box-shadow: var(--wideMediaPod-root-boxShadow);

    &__brand-bar {
        height: 1.6rem;
        border: none;
        background: var(--wideMediaPod-brandBar-background);
    }

    &__media {
        overflow: hidden;
        position: relative;
        width: 50%;
    }

    &__image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease-in-out transform;
        transform-origin: center;

        &::after {
            display: block;
            padding-top: calc((10 / 16) * 100%);
            content: '';
        }

        &:hover {
            #{$this}__video-play {
                transform: scale(1.15);
            }

            #{$this}__image {
                transform: scale(1.05);
            }
        }

        .lazy-image-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
    }

    &__image {
        transition: 0.3s ease-in-out transform;
    }

    &__video-duration,
    &__gallery-size {
        display: inline-block;
        padding: 0.2rem 0.6rem;
        color: var(--videoDuration-tag-color);
        font-size: 1.2rem;
        font-weight: $bold;
        text-decoration: none;
        background-color: var(--videoDuration-tag-backgroundColor);
        z-index: $level-one;
        position: absolute;
        bottom: 1.6rem;
        left: 1.6rem;
    }

    &__gallery-grid-container {
        transition: 0.3s ease-in-out transform;

        &:hover {
            transform: scale(1.05);
        }
    }

    &__video-play {
        z-index: $level-one;
        position: relative;
        transform-origin: center;
        transition: 0.3s ease-in-out transform;
    }

    &__video-play-icn {
        height: 12rem;
        width: 12rem;
        background: rgba($black, 0.7);
        fill: $white;
        border-radius: 10rem;
    }

    &__heading {
        @include heading-L;
        color: var(--wideMediaPod-title-color);
    }

    &__summary {
        @include body-medium;
        color: var(--wideMediaPod-summary-color);
    }

    &__inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        &.is-reversed {
            flex-direction: row-reverse;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding: 5.6rem;
        gap: 1.6rem;
    }

    &__cta-container {
        margin-top: 2.4rem; // combines with gap to create 4rem spacing
    }

    @media (max-width: $break-phablet) {
        &__brand-bar {
            height: 0.8rem;
        }

        &__inner {
            flex-direction: column;

            &.is-reversed {
                flex-direction: column-reverse;
            }
        }

        &__summary {
            @include body-medium;
        }

        &__content {
            flex: 1;
            width: 100%;
            padding: 2.4rem;
            gap: 0.8rem;
        }

        &__media {
            width: 100%;
        }

        &__video-play-icn {
            height: 7.2rem;
            width: 7.2rem;
        }

        &__video-duration,
        &__gallery-size {
            bottom: 0.5rem;
            left: 0.5rem;
        }

        &__cta-container {
            margin-top: 1.6rem; // combines with gap to create 2.4rem spacing
        }
    }
}
