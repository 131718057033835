// Styles for: Filter dropdown

// CONFIG

:root {
    --filter-group-backgroundColor: var(--g-component-background);
    --filter-group-textColor: var(--g-typography-color-widget-component);
    --filter-group-resetTextColour: var(--g-typography-color-widget-component);
}

.filter-group {
    $this: &;
    display: flex;
    padding: 2.4rem 0;
    box-shadow: var(--c-popout-shadow-default);

    &__reset-filters {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        font-size: 1.2rem;
        font-weight: $extrabold;
        line-height: 1;

        &-icon {
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    &__wrapper {
        display: flex;
        gap: 2.4rem;
    }

    &__inner-container {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        color: var(--filter-group-textColor);

        &.active {
            #{$this}__list {
                display: block;
            }

            #{$this}__dropdown {
                &::after {
                    transform: translateY(-50%) rotate(-180deg);
                }
            }
        }
    }

    &__dropdown {
        position: relative;
        height: fit-content;
        background: var(--filter-group-backgroundColor);
        border-radius: 8rem;
        border: 1px solid rgba($color-dark, 0.1);
        font-size: 1.2rem;
        font-weight: $extrabold;
        text-overflow: ellipsis;
        line-height: 4rem;
        padding: 0 3.4rem 0 1.6rem;
        appearance: none;

        &:focus {
            color: var(--g-color-brand-primary);
            border-color: var(--g-color-brand-primary);

            option {
                color: var(--filter-group-textColor);
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__select-wrapper {
        position: relative;
    }

    &__select-icon {
        position: absolute;
        right: 2.56rem;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;

        &::before,
        &::after {
            content: '';
            display: block;
            border-inline-start: 5px solid transparent;
            border-inline-end: 5px solid transparent;
            border-top: 5px solid var(--filter-group-textColor);
            height: 0;
            pointer-events: none;
            position: absolute;
            top: calc(50% - 0.4rem);
            transform: translateY(-50%) rotate(-180deg);
            width: 0;
        }

        &::after {
            top: calc(50% + 0.3rem);
            transform: translateY(-50%);
        }
    }

    &__description {
        display: block;
        font-size: 1.2rem;
        line-height: 1;
        font-weight: $regular;
        white-space: nowrap;
        margin-bottom: 0;
    }

    &--no-shadow {
        box-shadow: none;
    }

    @media(max-width: $break-phablet) {
        flex-direction: column;
        align-items: center;
        padding: 0;

        &.is-open {
            box-shadow: var(--c-dropdown-shadow-default);

            #{$this}__open {
                opacity: 0;
                visibility: hidden;
            }

            #{$this}__close {
                opacity: 1;
                visibility: visible;
            }

            #{$this}__wrapper {
                padding: 1.6rem 0;
                max-height: none;
                overflow: visible;
            }
        }

        &__heading {
            display: flex;
            align-items: center;
            position: relative;
            gap: 0.8rem;
            width: 100%;
            padding: 1.6rem 0.4rem;
            font-size: 1.6rem;
            font-weight: $bold;
            line-height: 1;

            &--icon {
                width: 1.6rem;
                height: 1.6rem;
            }
        }

        &__open {
            position: absolute;
            top: 50%;
            right: 2.6rem;

            &::before,
            &::after {
                content: '';
                display: block;
                border-inline-start: 6px solid transparent;
                border-inline-end: 6px solid transparent;
                border-top: 6px solid var(--filter-group-textColor);
                height: 0;
                pointer-events: none;
                position: absolute;
                top: calc(50% - 0.4rem);
                transform: translateY(-50%) rotate(-180deg);
                width: 0;
            }

            &::after {
                top: calc(50% + 0.4rem);
                transform: translateY(-50%);
            }
        }

        &__close {
            position: absolute;
            top: 2rem;
            right: 1.6rem;
            opacity: 0;
            visibility: hidden;

            &-icon {
                width: 1.2rem;
                height: 1.2rem;
            }
        }

        &__wrapper {
            display: flex;
            gap: 1.6rem;
            flex-direction: column;
            width: 100%;
            border-top: 1px solid rgba($color-dark, 0.1);
            max-height: 0;
            overflow: hidden;
            padding: 0;
            transition: max-height 0.25s ease;
        }

        &__inner-container {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 0.4rem;
            width: 100%;
        }

        &__dropdown,
        &__select-wrapper {
            width: 100%;
        }

        &__reset-filters {
            justify-content: center;
        }
    }
}
