// Mixins

@mixin clearfix {
    // Stops element with floated children from collapsing

    &::before {
        content: '';
        display: block;
    }

    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

/* mixin for multiline */
@mixin multi-line-ellipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    margin-right: -1em;
    padding-right: 1em;

    &::before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}

/*
 * When a full-width widget is inside a container, this mixin uses viewport width to break it out of that container
 * Note that overflow-x: hidden should be applied to to <body>, as the vw calculation does not take into account static scrollbar width
 */

@mixin full-width-widget {
    width: 100%;

    @supports (width: 100vw) {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        @media (max-width: $break-tablet) {
            width: auto;
            margin-left: -2rem;
            margin-right: -2rem;
            left: auto;
            right: auto;
        }

        @media (max-width: $break-phablet) {
            margin-left: -1rem;
            margin-right: -1rem;
        }
    }
}

/*
 * Adds hover and focus styles for tablet (by default) and up
 * Only keeps focus styles for below tablet (by default)
 * If focus set to false then only hover styling will be applied
 */

@mixin hover($breakpoint: $break-tablet, $focus: true) {
    @if $focus == true {
        &:focus {
            @content;
        }
    }
    @media (min-width: $breakpoint) {
        &:hover {
            @content;
        }
    }
}

@mixin retina-bg($file, $type, $width, $height) {
    // stylelint-disable-next-line function-url-quotes
    background-image: url($file + '.' + $type);

    // stylelint-disable-next-line media-feature-name-no-unknown
    @media (minpixel-ratio: 2), (min-device-pixel-ratio: 2) {
        & {
            // stylelint-disable-next-line function-url-quotes
            background-image: url($file + '@2x.' + $type);
            background-size: $width $height;
        }
    }
}

@mixin retina-bg-cover($file, $type) {
    // stylelint-disable-next-line function-url-quotes
    background-image: url($file + '.' + $type);
    background-size: cover;
    background-repeat: no-repeat;

    // stylelint-disable-next-line media-feature-name-no-unknown
    @media (minpixel-ratio: 2), (min-device-pixel-ratio: 2) {
        & {
            // stylelint-disable-next-line function-url-quotes
            background-image: url($file + '@x2.' + $type);
        }
    }
}

/*
 * Gives a container with a fixed position child element equal dimensions
 * to prevent content from moving behind
 */

@mixin fixed-container($fixed-container-height) {
    width: 100%;
    height: $fixed-container-height;
    display: block;
    position: relative;
    top: 0;
}

/*
 * Applies an ellipsis to text that overflows the width passed into the mixin
 * NB this ONLY works on single lines of text
 */

@mixin truncate($width: 100%) {
    width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Unit stripper [10px = 10]

@function strip-unit($num) {
    @return calc($num / ($num * 0 + 1));
}

// PX and REM constrictor

@mixin unit-constructor($property, $values...) {
    $max: length($values);
    $px-values: '';
    $rem-values: '';
    @for $i from 1 through $max {
        $value: strip-unit(nth($values, $i));
        $px-values: #{$px-values + $value * $pixel-base}px;

        @if $i < $max {
            $px-values: #{$px-values + ' '};
        }
    }
    @for $i from 1 through $max {
        $value: strip-unit(nth($values, $i));
        $rem-values: #{$rem-values + $value}rem;

        @if $i < $max {
            $rem-values: #{$rem-values + ' '};
        }
    }
    @if $use-px == true {
        #{$property}: $px-values;
    } @else if $use-px == false {
        #{$property}: $rem-values;
    } @else {
        #{$property}: $px-values;
        #{$property}: $rem-values;
    }
}

@mixin u($property, $values...) {
    @include unit-constructor($property, $values...);
}

@mixin link-reset {
    // remove default browser styling of a link tag
    color: inherit;
    text-decoration: none;
}

/*
 * Applies an ellipsis to text that overflows the amount of lines given
 *
 * max-height and oveflow hidden workaround for non-webkit browsers
 */

@mixin truncate-multiline($line, $line-height) {
    max-height: $line * $line-height;
    overflow: hidden;

    @supports (-webkit-line-clamp: $line) {
        // stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix
        display: -webkit-box;
        -webkit-line-clamp: $line;
        -webkit-box-orient: vertical;
        align-self: stretch;
        // stylelint-enable
    }
}

@mixin remove-wrapper-pseudos() {

    &::before,
    &::after {
        display: none;
    }
}
