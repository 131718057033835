.w-mc-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 94vw;
    max-width: 70rem;
    margin: auto;

    &__wrapper {
        margin: 4rem auto;
        max-width: 70rem;
        text-align: center;
        width: 94vw;
    }

    &__heading {
        text-transform: uppercase;
    }

    &__team,
    &__cell--value {
        width: 22rem;
    }

    &__team-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;
        @include heading-XS;
        text-transform: uppercase;
    }

    &__team--home &__team-wrapper {
        flex-direction: row-reverse;
    }

    &__team-badge {
        display: flex;
        width: 2.4rem;
    }

    &__cell {
        padding: 1.4rem 0;
        text-align: center;

        &--best {
            font-weight: bold;
        }

        &--label {
            @include text-copy;
            font-size: 1.4rem;
        }
    }

    @media (max-width: $break-tablet) {

        &__team,
        &__cell--value {
            width: 34%;
        }

        &__team-badge {
            display: none;
        }
    }

    @media (max-width: $break-phablet) {

        &__cell {
            padding: .8rem 0;
        }

        &__team-wrapper,
        &__cell--label {
            font-size: 1.2rem;
        }

        &__cell--value {
            font-size: 1.4rem;
        }
    }
}
