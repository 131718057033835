:root {
    --standings-win-color: var(--g-color-football-win);
    --standings-lose-color: var(--g-color-football-lose);
    --standings-draw-color: var(--g-color-football-draw);
    --standings-my-club-color: var(--g-color-system-mid);
    --standings-my-club-text-color: var(--c-color-black);
}

.standings-table {
    $this: &;
    color: $black;
    width: 100%;
    text-align: center;
    table-layout: fixed;
    margin-bottom: 1rem;

    .is-loading & {
        opacity: 0.5;
    }

    &__header-cell {
        padding: 0.8rem 0;
        @include heading-XXS;
        width: 3%;
        opacity: 1;
        text-align: center;
        border-inline: none;

        &--position {
            width: 3%;
            padding-left: 0.5rem;
            text-align: left;
        }

        &--club {
            width: 8%;
            text-align: left;
            padding-left: 0;
        }

        &--form {
            width: 6%;
        }

        &--next {
            [data-show-club-badges="false"] & {
                display: none;
            }
        }
    }

    &__abbr {
        position: relative;

        &:hover {
            #{$this}__tooltip {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__tooltip {
        background-color: var(--g-color-brand-primary);
        color: $white;
        font-size: 1.2rem;
        padding: 0.5rem 0.8rem;
        position: absolute;
        bottom: calc(100% + 0.8rem);
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease;

        &--start {
            left: 0.2rem;
            transform: translateX(0);
        }

        &--end {
            left: auto;
            right: 0.2rem;
            transform: translateX(0);
        }
    }

    &__group-name {
        margin: 3rem 0 2rem;
    }

    &__cell {
        font-size: 1.7rem;
        padding: 1.6rem 0;
        overflow: hidden;
        border-inline: none;
        text-align: inherit;
    }

    &__row {
        position: relative;
        border-bottom: 0.1rem solid $grey-300;
        background: var(--g-widget-background);

        &:nth-child(even) {
            background: var(--g-widget-background);
        }

        &--highlighted {
            background-color: var(--standings-my-club-color) !important;
            color: var(--standings-my-club-text-color) !important;
        }
    }

    &__pos-value,
    &__points {
        font-size: 1.7rem;
    }

    &__pos-value {
        flex-basis: 3.2rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__club-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
    }

    &__club {
        text-align: left;
    }

    &__team {
        #{$this}__team-name {
            font-size: 1.7rem;
        }
    }

    &__form-wrapper {
        display: flex;
        align-content: center;
        justify-content: center;
        gap: 0.3rem;
    }

    &__form-container {
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 50%;
        overflow: hidden;

        &:hover {
            .tooltip {
                display: block;
            }
        }
    }

    &__form-result {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        color: $white;
        display: contents;
        width: 100%;
        height: 100%;
    }

    &__form-indicator {
        @include heading-XXS;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.2rem;

        &:focus {
            .tooltip {
                display: block;
            }
        }

        &--draw {
            background-color: var(--standings-draw-color);

            #{$this}__form-result {
                color: $black;
            }
        }

        &--win {
            background-color: var(--standings-win-color);
        }

        &--lose {
            background-color: var(--standings-lose-color);
        }
    }

    &__badge {
        display: block;
        height: 3.2rem;
        width: 3.2rem;

        [data-show-club-badges="false"] & {
            display: none;
        }
    }

    &__next {
        [data-show-club-badges="false"] & {
            display: none;
        }
    }

    &__next-badge {
        display: flex;
        height: 4rem;
        width: 2.6rem;
    }

    &__next-match {
        display: flex;
        justify-content: center;
    }

    &__position {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1.6rem 0;
        position: relative;
    }

    &__pos-arrow {
        width: 1rem;
        height: 1rem;

        &--non {
            width: 0.7rem;
        }
    }

    &__pos-movement {
        flex-basis: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    abbr {
        border-bottom: 0;
        text-decoration: none;
    }

    @media (max-width: $break-phablet) {
        &__pos-movement {
            display: none;
        }

        &__badge,
        &__next-badge {
            height: 2.7rem;
            width: 2.4rem;
        }

        &__team-name,
        &__cell,
        &__pos-value,
        &__points {
            font-size: 1.4rem;
        }

        &__header-cell {
            width: 2%;
        }

        &__header-cell--club {
            width: 8%;
        }

        &__header-cell--position {
            width: 2%;
            text-align: left;
            padding-left: 0.4rem;

            [data-table-type='form'] & {
                width: 2.5%;
            }
        }

        &__header-cell--form {
            width: 6%;
        }

        &__header-cell--next {
            width: 4%;
        }

        &__team {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__group-name {
            margin: 2rem 0;
        }

        &__form-container {
            width: 2.1rem;
            height: 2.1rem;
        }

        &__form-indicator {
            padding-top: 0.1rem;
        }

        &__form-result {
            position: relative;
            display: block;
            top: auto;
            left: auto;
            transform: none;
        }
    }

    @media (max-width: $break-mobile) {
        &__form-container {
            width: 1.6rem;
            height: 1.6rem;
        }

        &__form-result {
            font-size: 1rem;
        }

        &__team-name {
            font-size: 1.2rem !important; // stylelint-disable-line declaration-no-important
        }
    }
}
