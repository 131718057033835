// Styles for: "Football: Player Hero"

:root {
    --football-player-hero-back-to-color: var(--c-color-white);
}

$hero-bg-color: var(--football-player-hero-bg-color); // For use in sass funcs

.w-player-hero {
    $this: &;
    $image-min-width: 45rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--g-font-color);

    &__info-wrapper {
        display: flex;
        gap: 5rem;

        &::before,
        &::after {
            display: none;
        }
    }

    &__info-section {
        width: 100%;
        display: flex;
        position: relative;
        gap: 4rem;
        padding: 4rem 2rem 0;
        margin-bottom: 4rem;
        color: var(--c-color-white);
        background-color: var(--g-color-brand-primary);
    }

    &__info {
        flex-grow: 1;
        padding-bottom: 1.6rem;
    }

    &__image-section {
        align-self: flex-end;
        position: relative;
        min-width: $image-min-width;

        > .lazy-image-wrapper {
            display: flex;
            align-items: flex-end;
            position: relative;
            height: 100%;
        }

        &.o-placeholder {
            background-color: unset;
        }

        .o-placeholder__svg {
            max-width: 12rem;
            margin: 12rem auto;
        }
    }

    &__image-container {
        display: flex;
        height: min-content;
    }

    &__image {
        width: 100%;
        height: auto;
        object-fit: cover;
        opacity: 1;
    }

    &__top-bar {
        display: flex;
        justify-content: space-between;
        padding-bottom: 3rem;
    }

    &__back-to-team-link {
        &, &:link {
            @include heading-XXS;
            white-space: nowrap;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            gap: .8rem;
            padding: 1.4rem 2rem;
            border-radius: 5.6rem;
            border: .1rem solid $border-color-inverted-color;
            opacity: 1;
            transition: opacity .2s ease;
            text-decoration: none;
            color: var(--football-player-hero-back-to-color);
        }

        .button__icon {
            height: 1.3rem;
            width: 1.3rem;
            fill: var(--football-player-hero-back-to-color);

            &:lang(ar) {
                rotate: -180deg;
            }
        }

        &:hover {
            opacity: .7;
        }
    }

    &__back-text {
        margin-top: .3rem;
    }

    &__social-container {
        display: flex;
        align-items: center;
        gap: 1.6rem;
    }

    &__follow-icon-container {
        display: flex;
        align-items: center;
        gap: 1.2rem;
    }

    &__follow-text {
        @include heading-XXS;
        color: var(--g-color-stone-100)
    }

    &__follow-icon {
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        color: var(--g-font-color);
        fill: var(--c-color-white);
        opacity: 1;
        transition: opacity .2s ease;

        &:hover {
            opacity: .8;
        }
    }

    &__player-info-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 3rem;
        gap: 0.8rem;
    }

    &__player-shirt-name {
        display: flex;
        gap: 2.4rem;
        @include heading-L;
    }

    &__shirt-num {
        opacity: .5;
    }

    &__last-name {
        @include heading-XXL;
    }

    &__player-info-bottom {
        display: flex;
        gap: 1.6rem;
    }

    &__info-item {
        position: relative;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: auto;
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
        background-color: rgba($white, .1);
    }

    &__info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__info-item-title {
        @include text-copy;
        text-transform: uppercase;
        color: var(--g-color-stone-100);
    }

    &__info-item-value {
        @include heading-S;
        display: flex;
        align-items: center;
        gap: .8rem;

        img {
            width: 3rem;
            border-radius: .4rem;
        }
    }

    @media (max-width: $break-wide) {
        &.u-inverted-color-theme {
            padding-top: 0;
        }

        &__info-section {
            width: 100%;
        }

        &__image-section {
            min-width: 35rem;
            width: 30%;
        }

        &__player-info-bottom {
            flex-wrap: wrap;
            gap: 1.6rem;
        }

        &__info-item {

            flex-basis: calc(50% - .8rem);
            text-align: center;
        }
    }

    @media (max-width: $break-desktop) {
        &__wrapper {
            padding: 0;
            gap: 0;
        }

        &__image-section {
            width: 40vw;
            min-width: 0;
        }

        &__player-shirt-name {
            @include heading-M;
        }

        &__last-name {
            @include heading-L;
        }

        &__info-item {
            padding-top: 1.6rem;
            padding-bottom: 1.6rem;
        }

        &__info-item-value {
            @include heading-XS;
        }
    }

    @media(max-width: $break-tablet) {

        &__image-section {
            width: 50vw;
            min-width: 0;
        }
    }

    @media (max-width: $break-phablet) {

        &__top-bar {
            order: 2;
            padding: 1.6rem 0 2rem;
            justify-content: center;
        }

        &__player-shirt-name {
            justify-content: center;
            gap: .8rem
        }

        &__team {
            display: none;
            width: 6.4rem;
            height: 5.6rem;
            margin: 0 auto;
        }

        &__back-to-team-link {
            position: absolute;
            top: 1.8rem;
            left: 2.4rem;
            height: 4.4rem;
            width: 4.4rem;

            &:lang(ar) {
                left: auto;
                right: .8rem;
            }

            &, &:link {
                padding: 1.4rem;
                border-radius: 50%;
            }

            .button__icon {
                height: 2rem;
                width: 2rem;

                &:lang(ar) {
                    rotate: 180deg;
                }
            }
        }

        &__follow-icon {
            width: 2rem;
            height: 2rem;
        }

        &__info-item-value {
            @include heading-XXS;

            img {
                width: 2.1rem;
            }
        }
    }

    @media (max-width: 500px) {

        &__info-wrapper {
            flex-direction: column;
            gap: 1rem;
        }

        &__player-info-top {
            justify-content: center;
        }

        &__player-shirt-name {
            @include heading-L;
        }

        &__last-name {
            @include heading-XL;
        }

        &__image-section {
            align-self: center;
        }
    }
}
