.youtube-video {
    $this: &;
    overflow: hidden;
    width: 100%;
    pointer-events: none;

    &__iframe {
        position: absolute;
        width: 100%;
        height: 130%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--full-width {
        #{$this}__iframe {
            height: 170%;
        }
    }

    @media (max-width: $break-desktop) {
        &__iframe {
            width: 200%;
        }

        &--full-width {
            #{$this}__iframe {
                height: 100%;
            }
        }
    }
}
