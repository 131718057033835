// Styles for: Wide media pod
// Notes: Wide media pod style for all devices

// CONFIG

:root {
    --o-overlay-media-pod-root-backgroundColor: var(--g-component-background);
    --o-overlay-media-pod-root-borderRadius: var(--g-layout-border-radius);
    --o-overlay-media-pod-root-border: var(--c-border-none);
    --o-overlay-media-pod-root-boxShadow: var(--g-layout-box-shadow);
    --o-overlay-media-pod-text-color: var(--c-color-white);
    --o-overlay-media-pod-title-fontSize-desktop: var(--c-title-large-desktop);
    --o-overlay-media-pod-title-fontSize-desktop-large: var(
        --c-title-xlarge-desktop
    );
    --o-overlay-media-pod-ctaText-color-default: var(--c-color-white);
    --o-overlay-media-pod-ctaText-color-hover: var(--c-color-white);
    --o-overlay-media-pod-overlayColor: var(--c-color-black);
}

// GRADIENT MEDIA POD

.o-overlay-media-pod {
    $this: &;
    $content-tile-gutter: 1.6rem;
    width: 100%;
    border: var(--o-overlay-media-pod-root-border);
    border-radius: var(--o-overlay-media-pod-root-borderRadius);
    overflow: hidden;
    background-color: var(--o-overlay-media-pod-root-backgroundColor);
    box-shadow: var(--o-overlay-media-pod-root-boxShadow);

    &__media {
        overflow: hidden;
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        transition: 0.3s ease-in-out transform;
    }

    &__image-container {
        display: block;
        width: 100%;
        transition: 0.3s ease-in-out transform;

        &::after {
            display: block;
            padding-top: calc((10 / 16) * 100%);
            content: '';
        }

        &:hover {
            #{$this}__video-play {
                transform: scale(1.15) translate(-50%, -50%);
            }

            #{$this}__image {
                transform: scale(1.05);
            }
        }
    }

    &__gallery-grid-container {
        width: calc(100% + 0.4rem);
    }

    &__image {
        transition: 0.3s ease-in-out transform;
    }

    &__video-duration,
    &__gallery-size {
        display: inline-block;
        padding: 0.2rem 0.6rem;
        color: var(--videoDuration-tag-color);
        font-size: 1.2rem;
        font-weight: $bold;
        text-decoration: none;
        background-color: var(--videoDuration-tag-backgroundColor);
        z-index: $level-one;
        position: absolute;
        bottom: 1.6rem;
        left: 1.6rem;
    }

    &__video-play {
        z-index: $level-one;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: top;
        transition: 0.3s ease-in-out transform;
    }

    &__video-play-icn {
        height: 12rem;
        width: 12rem;
    }

    &__heading {
        @include heading-L;
        letter-spacing: -0.08rem;
        color: var(--o-overlay-media-pod-text-color);
        line-height: 1.2;
        @include truncate-multiline(2, 1.2);
    }

    &__summary {
        @include body-medium;
        margin-top: 1.2rem;
        color: var(--o-overlay-media-pod-text-color);
        overflow: hidden;
        line-height: 1;
        @include truncate-multiline(1, 1);
    }

    &__wrapper {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 54rem;
        min-height: 30rem;
        max-height: calc(100vh - 12.7rem);
        text-decoration: none;

        &.is-reversed {
            flex-direction: row-reverse;
        }

        &--link {
            @include hover {
                @include arrow-link-parent-hover;

                #{$this}__heading {
                    text-decoration: underline;
                }

                #{$this}__media {
                    transform: scale(1.05);
                }
            }
        }

        .lazy-image-wrapper {
            width: 100%;
            height: 100%;
        }
    }

    &--youtube {
        #{$this}__icon {
            width: 10rem;
            height: 2.2rem;
        }

        #{$this}__wrapper {
            height: 64rem;
        }
    }

    &__inner {
        padding: 3.2rem 4.4rem;
    }

    &__content-wrapper {
        display: flex;
        gap: $content-tile-gutter;
    }

    &__content {
        display: flex;
        z-index: $level-one;
        flex-direction: column;
        justify-content: flex-end;
        max-width: 80rem;
        min-width: calc(
            50% - $content-tile-gutter
        ); // 1.6rem is gap between two items
        flex: 1;
    }

    &__icon {
        fill: var(--o-overlay-media-pod-text-color);
        width: 2.4rem;
        height: 2.4rem;

        &--pause {
            display: none;
        }
    }

    &__content-meta {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        margin-bottom: 0.8rem;
    }

    &__cta-container {
        margin-top: 2.4rem;

        .o-arrow-link {
            color: var(--o-overlay-media-pod-ctaText-color-default);

            svg {
                fill: var(--o-overlay-media-pod-ctaText-color-default);
            }
        }
    }

    &__background {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &::before,
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &--centre #{$this} {
        &__inner,
        &__content-meta {
            display: flex;
            justify-content: center;
        }

        &__content-meta {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__content {
            text-align: center;
        }
    }

    .o-content-meta {
        &__item {
            &::before {
                background: var(--o-overlay-media-pod-ctaText-color-default);
            }

            &--text {
                color: var(--o-overlay-media-pod-ctaText-color-default);
            }

            &--tag {
                color: var(--o-overlay-media-pod-ctaText-color-default);

                // Remove background and padding
                background: none;
                padding: 0;
            }
        }
    }

    .inline-player {
        &__content {
            position: absolute;
            width: 100%;
            height: 100%;
            padding-top: 56.25%;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    .o-placeholder {
        position: absolute;
    }

    .vjs {
        &-tech {
            height: auto;
            min-height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-big-play-button,
        &-dock-text,
        &-control-bar {
            display: none;
        }

        &-poster {
            background-size: cover;
        }
    }

    @media (max-width: $break-desktop) {
        .vjs {
            &-tech {
                min-width: 96rem;
            }
        }
    }

    @media (max-width: $break-tablet) {
        &__content-wrapper {
            flex-direction: column;
            gap: 0;
        }

        &__content {
            width: 100%;
        }

        &--youtube {
            #{$this}__wrapper {
                height: 50rem;
            }
        }
    }

    @media (max-width: $break-tablet) {
        &--youtube {
            #{$this}__wrapper {
                height: 40rem;
            }
        }
    }

    @media (max-width: $break-phablet) {
        &__wrapper {
            height: 40rem;
            max-height: none;

            &.is-reversed {
                flex-direction: column-reverse;
            }
        }

        &__heading {
            line-height: 1.1;
            @include truncate-multiline(3, 1.1);
        }

        &__summary {
            @include text-copy;
            font-size: 1.4rem;
            font-weight: $regular;
            line-height: 2.1rem;
            margin-top: 0.8rem;
        }

        &__inner {
            width: 100%;
            max-width: none;
            padding: 1.6rem;
        }

        &__media {
            width: calc(100% + 0.2rem);
            flex-wrap: wrap;
        }

        &__icon {
            width: 1.6rem;
            height: 1.6rem;
        }

        &__content-meta {
            margin-bottom: 0.8rem;
        }

        &__video-play-icn {
            height: 7.2rem;
            width: 7.2rem;
        }

        &__video-duration,
        &__gallery-size {
            bottom: 0.5rem;
            left: 0.5rem;
        }

        &__cta-container {
            margin-top: 1.2rem;
        }

        &--centre #{$this} {
            &__inner {
                max-height: 22.5rem;
            }

            &__content {
                height: 100%;
                max-height: 19.5rem;
            }

            &__content-meta {
                gap: 0;
            }

            &__content-meta-icon {
                margin-bottom: 0.8rem;
            }

            &__heading {
                min-height: 3.4rem;
            }
        }

        .vjs {
            &-tech {
                min-width: 71.2rem;
            }
        }
    }
}
