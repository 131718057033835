// Styles for: Video duration
// Notes: Video duration style for all devices

// CONFIG

:root {
    --videoDuration-tag-backgroundColor: var(--g-color-system-dark);
    --videoDuration-tag-color: var(--g-color-system-light);
}

// VIDEO DURATION

.o-video-duration {
    display: inline-block;
    padding: 0.2rem 0.6rem;
    color: var(--videoDuration-tag-color);
    font-size: 1.2rem;
    text-decoration: none;
    background-color: var(--videoDuration-tag-backgroundColor);
}
