// Styles for: transition
// Notes: transition style for all devices

.fade-in-on-load {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;

    &.is-loaded {
        opacity: 1;
    }
}
