:root {
    --standings-accent-colour: var(--g-color-brand-primary)
}

.standings-table {
    $this: &;

    thead {
        border-bottom-color: var(--standings-accent-colour);
    }

    &__row {
        &--hero {
            background-color: var(--standings-accent-colour) !important;
            color: $white;
        }

        #{$this} {
            &__cell {
                padding: .8rem 0;
            }

            &__cell,
            &__pos-value {
                font-size: 1.4rem;
            }

            &__team {
                #{$this} {
                    &__team-name {
                        font-weight: $bold;
                        font-size: 1.4rem;
                    }
                }
            }

            &__badge {
                width: 2.5rem;
                height: 2.5rem;
            }


        }
    }

    &__header {
        display: flex;
        flex-direction: column;
    }

    &__info {
        display: flex;
        flex-direction: column;
    }

    &__title {
        @include heading-S;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    &__team-type {
        font-size: 1.5rem;
        font-weight: $bold;
        margin: 1.2rem 0;
    }

    &__comp-icon {
        display: block;
        height: 4rem;
        width: 10rem;
        margin: .5rem 0;
    }

    @media(max-width: $break-desktop) {

        &__title {
            font-size: 3rem;
        }

        &__team-type {
            font-size: 1.2rem;
            margin: 0.8rem 0;
        }
    }

    @media(max-width: $break-phablet) {

        &__title {
            font-size: 2.5rem;
        }

        &__team-type {
            font-size: 1rem;
        }
    }
}
