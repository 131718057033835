// stylelint-disable at-rule-empty-line-before

$use-px: false;

/* SETTINGS
========================================================================== */

@import 'includes/settings/normalize';

@import 'includes/settings/variables';

@import 'includes/global/fonts';

@import 'includes/global/mixins';

/* LAYOUT
========================================================================== */

@import 'includes/layout/main';

@import 'includes/layout/block-list';

@import 'includes/layout/grid-list';

/* GLOBAL
========================================================================== */

@import 'includes/global/global';

@import 'includes/global/typography';

@import 'includes/global/animations';

@import 'includes/global/transitions';

/* THEMES
========================================================================== */

@import "includes/themes/_example-theme.scss";
@import "includes/themes/_theme-function.scss";

/* COMPONENTS
========================================================================== */

@import "includes/components/_content-slider.scss";
@import "includes/components/_embed-widgets.scss";
@import "includes/components/_filter-group.scss";
@import "includes/components/_filter.scss";
@import "includes/components/_forms.scss";
@import "includes/components/_o-arrow-link.scss";
@import "includes/components/_o-brand-bar.scss";
@import "includes/components/_o-content-meta.scss";
@import "includes/components/_o-cta.scss";
@import "includes/components/_o-custom-checkbox.scss";
@import "includes/components/_o-gameweek.scss";
@import "includes/components/_o-large-media-pod.scss";
@import "includes/components/_o-match-card.scss";
@import "includes/components/_o-media-pod.scss";
@import "includes/components/_o-overlay-media-pod.scss";
@import "includes/components/_o-placeholder.scss";
@import "includes/components/_o-promo-pod.scss";
@import "includes/components/_o-video-duration.scss";
@import "includes/components/_o-wide-media-pod.scss";
@import "includes/components/_o-wide-small-media-pod.scss";
@import "includes/components/_o-widget-card.scss";
@import "includes/components/_o-widget-header.scss";
@import "includes/components/_object-fit-cover-picture.scss";
@import "includes/components/_object-fit-cover-video.scss";
@import "includes/components/_page-nav.scss";
@import "includes/components/_pager.scss";
@import "includes/components/_picture.scss";
@import "includes/components/_progress-bar.scss";
@import "includes/components/_related-content-thumbnail.scss";
@import "includes/components/_youtube-video.scss";

/* WIDGETS
========================================================================== */

/** If widget folder structure is changed this needs updating **/

@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/football-player-list/styles/_football-player-card.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/football-player-list/styles/_football-player-list.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/football-player/styles/_player-bio.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/football-player/styles/_player-hero.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/football-player/styles/_player-stats.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/match-centre/styles/_head-to-head.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/match-centre/styles/_match-centre-header.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/match-centre/styles/_match-centre-lineups.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/match-centre/styles/_match-centre-stats.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/match-centre/styles/_match-centre-tabs.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/match-centre/styles/_stats-overview-header.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/mc-timeline/styles/_o-mc-player-card.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/mc-timeline/styles/_o-mc-timeline.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/related-content/styles/related-content.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/results-and-standings/styles/match.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/results-and-standings/styles/results-and-standings.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/results-and-standings/styles/standings-table.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/standings/styles/_standings-table.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/football/standings/styles/_standings.scss";

// stylelint-enable
