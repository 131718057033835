// Styles for: object fit cover video
// Notes: object fit cover video style for all devices

.object-fit-cover-video {
    &__iframe {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
    }
}
