// Styles for: Promo pod
// Notes: Promo pod style for all devices

// CONFIG

:root {
    --promoPod-root-backgroundColor: var(--g-component-background);
    --promoPod-root-boxShadow: var(--g-layout-box-shadow);
    --promoPod-root-border: var(--c-border-none);
    --promoPod-root-borderRadius: var(--g-layout-border-radius);
    --promoPod-brandBar-background: var(--g-color-brand-primary);
    --promoPod-title-color: var(--g-color-system-dark);
    --promoPod-summary-color: var(--g-color-system-dark);
}

// MEDIA POD

.o-promo-pod {
    $this: &;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    width: 100%;
    border: var(--promoPod-root-border);
    border-radius: var(--promoPod-root-borderRadius);
    overflow: hidden;
    background-color: var(--promoPod-root-backgroundColor);
    box-shadow: var(--promoPod-root-boxShadow);
    transition: transform 0.15s ease-in-out;

    &__brand-bar {
        height: 0.8rem;
        border: none;
        background: var(--promoPod-brandBar-background);
    }

    &__link {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        text-decoration: none;
    }

    &__image-container {
        &::before,
        &::after {
            display: block;
            padding-top: calc((10 / 16) * 100%);
            content: '';
        }

        &::before {
            position: absolute;
            margin-top: calc(((10 / 16) * 100%) + 0.1rem);
            width: 100%;
            background: var(--promoPod-root-backgroundColor);
        }
    }

    &__secondary-image-container {
        width: 100%;
        max-width: 15rem;
        height: auto;
        margin-right: 0.8rem;
        align-self: center;
        position: relative;

        &::after {
            display: block;
            padding-top: calc((1 / 1) * 100%);
            content: '';
        }
    }

    &__heading {
        @include heading-S;
        margin-bottom: 0.8rem;
        color: var(--promoPod-title-color);
    }

    &__summary {
        @include body-small;
        color: var(--promoPod-summary-color);
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &__content {
        position: relative;
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
        padding: $spacing-l;
        @each $size,
            $values
                in (
                    small: (
                        1.6rem,
                        0.8rem
                    ),
                    medium: (
                        2.4rem,
                        1.2rem
                    ),
                    large: (
                        3.2rem,
                        1.6rem
                    )
                )
        {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: nth($values, 1);

                @media (max-width: $break-tablet) {
                    padding: nth($values, 2);
                }
            }
        }
    }

    &__content-inner {
        display: flex;
        flex-direction: column;
    }

    &__arrow-link-container {
        margin-top: auto;

        .o-arrow-link {
            margin-top: $spacing-s;
        }
    }

    @include hover {
        @include arrow-link-parent-hover;
        transform: scale(1.03);
        z-index: $level-one;

        #{$this}__heading {
            text-decoration: underline;
        }
    }

    @media (max-width: $break-phablet) {
        &__link {
            flex-grow: 1;
        }

        &__content {
            flex: 1;
            padding: $spacing-s;
        }
    }
}
