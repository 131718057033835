// Styles for: typography
// Notes: typography style for all devices
// stylelint-disable selector-max-type font-weight-notation, declaration-no-important

@mixin text-title {
    font-family: var(--g-typography-application-heading);
}

@mixin text-copy {
    font-family: var(--g-typography-application-paragraph);
}

@mixin text-button {
    font-family: var(--g-typography-application-button);
}

@mixin text-misc {
    font-family: var(--g-typography-application-misc);
}

.u-text-title {
    @include text-title;
}

.u-text-copy {
    @include text-copy;
}

body {
    @include text-copy;
    color: $text-copy;
}

strong {
    font-weight: $bold;
}

p {
    line-height: 2;

    /* stylelint-disable-next-line selector-max-type */
    a {
        text-decoration: none;
        color: var(--g-typography-color-link);
        font-weight: $bold;

        @include hover {
            text-decoration: underline;
        }
    }
}

// HEADINGS

// Global heading styles
// https://www.figma.com/file/PVzA2htrStNQ12J3y9nYkQ/EXP---BuildLab---Global-Styles?type=design&node-id=2%3A133&mode=design&t=DPtpTNkUhlBiq2Ky-1

/* stylelint-disable scss/at-mixin-pattern */
@mixin heading-XXL {
    @include text-title;
    font-size: var(--c-title-xxlarge-desktop);
    font-weight: $extrabold;

    @media (max-width: $break-phablet) {
        font-size: var(--c-title-xxlarge-mobile);
    }
}

@mixin heading-XL {
    @include text-title;
    font-size: var(--c-title-xlarge-desktop);
    font-weight: $extrabold;

    @media (max-width: $break-phablet) {
        font-size: var(--c-title-xlarge-mobile);
    }
}

@mixin heading-L {
    @include text-title;
    font-weight: $extrabold;
    font-size: var(--c-title-large-desktop);

    @media (max-width: $break-phablet) {
        font-size: var(--c-title-large-mobile);
    }
}

@mixin heading-M {
    @include text-title;
    font-weight: $extrabold;
    font-size: var(--c-title-medium-desktop);

    @media (max-width: $break-phablet) {
        font-size: var(--c-title-medium-mobile);
    }
}

@mixin heading-S {
    @include text-title;
    font-weight: $bold;
    font-size: var(--c-title-small-desktop);

    @media (max-width: $break-phablet) {
        font-size: var(--c-title-small-mobile);
    }
}

@mixin heading-XS {
    @include text-title;
    font-weight: $bold;
    font-size: var(--c-title-xsmall-desktop);

    @media (max-width: $break-phablet) {
        font-size: var(--c-title-xsmall-mobile);
    }
}
/* stylelint-enable scss/at-mixin-pattern */

@mixin heading-XXS {
    @include text-title;
    font-weight: $bold;
    font-size: var(--c-title-xxsmall-desktop);

    @media(max-width: $break-phablet) {
        font-size: var(--c-title-xsmall-mobile);
    }
}

.t-heading-xxl {
    @include heading-XXL;
}

.t-heading-xl {
    @include heading-XL;
}

.t-heading-l {
    @include heading-L;
}

.t-heading-m {
    @include heading-M;
}

.t-heading-s {
    @include heading-S;
}

.t-heading-xs {
    @include heading-XS;
}

// BODY

@mixin body-large {
    @include text-copy;
    font-size: 2rem;
    line-height: 1.6;
    font-weight: $regular;

    @media (max-width: $break-mobile) {
        font-size: 2rem;
        line-height: 1.6;
    }
}

@mixin body-medium {
    @include text-copy;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: $regular;

    @media (max-width: $break-mobile) {
        font-size: 1.6rem;
        line-height: 1.5;
    }
}

@mixin body-small {
    @include text-copy;
    font-size: 1.4rem;
    line-height: 1.4;
    font-weight: $regular;

    @media (max-width: $break-mobile) {
        font-size: 1.4rem;
        line-height: 1.4;
    }
}

// META

@mixin meta-1 {
    @include text-misc;
    font-size: 1.2rem;
    line-height: 1.33;
    font-weight: $extrabold;
    letter-spacing: -0.02rem;

    @media (max-width: $break-mobile) {
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: normal;
    }
}

@mixin meta-2 {
    @include text-misc;
    font-size: 1.2rem;
    line-height: 1.33;
    font-weight: $regular;

    @media (max-width: $break-mobile) {
        font-size: 1rem;
        line-height: 1.4;
    }
}

// CTAs

@mixin cta {
    @include text-copy;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: $extrabold;
}

@mixin arrow-link {
    @include text-copy;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: $extrabold;
}

// CMS HEADINGS

@mixin cms-heading-1 {
    @include heading-XXL;
}

@mixin cms-heading-2 {
    @include heading-XL;
}

@mixin cms-heading-3 {
    @include heading-L;
}

@mixin cms-heading-4 {
    @include heading-M;
}

@mixin cms-heading-5 {
    @include heading-S;
}

@mixin cms-heading-6 {
    @include heading-XS;
}

@mixin cms-paragraph {
    @include body-medium;
}

@mixin cms-list {
    @include body-medium;
}
