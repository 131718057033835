/*
    These styles accompany the `widgetCard` macro

    Example Usage
    ==================
    <@widgetCard isFullWidth=true displayBrandBar=true>
        ...content here
    </@widgetCard>
*/

.o-widget-card {
    $this: &;

    &__container {
        overflow: hidden; // ensure brand bar adopts border-radius on outer card if present
    }

    &__brand-bar {
        height: 1rem;
        width: 100%;
        background: var(--g-color-gradient-1);
    }

    &--in-line {
        // Inline: apply wrapper mixin to whole element so card itself is contained in page wrapper
        @include wrapper;

        // Inline: apply rounded corners to card inner container
        #{$this}__container {
            border-radius: var(--g-layout-border-radius);
            box-shadow: var(--g-layout-box-shadow);
        }
    }

    &--full-width {
        width: 100%;

        // Full-width: apply wrapper mixin on card content to align with page wrapper
        #{$this}__content {
            @include wrapper(false);
        }
    }
}
