// Styles for: Wide small media pod
// Notes: Wide small media pod style for all devices

// CONFIG

:root {
    --wideSmallMediaPod-root-backgroundColor: var(--g-component-background);
    --wideSmallMediaPod-root-borderRadius: var(--g-layout-border-radius);
    --wideSmallMediaPod-root-border: var(--c-border-none);
    --wideSmallMediaPod-root-boxShadow: var(--g-layout-box-shadow);
    --wideSmallMediaPod-brandBar-background: var(--g-color-brand-primary);
    --wideSmallMediaPod-title-color: var(--g-color-system-dark);
    --wideSmallMediaPod-title-fontSize-desktop: var(--c-title-large-desktop);
    --wideSmallMediaPod-title-fontSize-desktop-large: var(
        --c-title-xlarge-desktop
    );
    --wideSmallMediaPod-title-fontSize-mobile: var(--c-title-xlarge-mobile);
    --wideSmallMediaPod-summary-fontSize-desktop: var(--c-text-medium-desktop);
    --wideSmallMediaPod-summary-fontSize-mobile: var(--c-text-medium-mobile);
    --wideSmallMediaPod-summary-color: var(--g-color-system-dark);
    --wideSmallMediaPod-ctaText-color-default: var(--g-color-system-light);
    --wideSmallMediaPod-ctaText-color-hover: var(--g-color-system-light);
    --wideSmallMediaPod-cta-backgroundColor-default: var(
        --g-color-brand-primary
    );
    --wideSmallMediaPod-cta-backgroundColor-hover: var(--g-color-brand-primary);
    --wideSmallMediaPod-cta-boxShadow-default: var(--g-layout-box-shadow);
    --wideSmallMediaPod-cta-boxShadow-hover: var(--g-layout-box-shadow);
}

// WIDE SMALL MEDIA POD

.o-wide-small-media-pod {
    $this: &;
    width: 100%;
    border: var(--wideSmallMediaPod-root-border);
    border-radius: var(--wideSmallMediaPod-root-borderRadius);
    overflow: hidden;
    background-color: var(--wideSmallMediaPod-root-backgroundColor);
    box-shadow: var(--wideSmallMediaPod-root-boxShadow);
    transition: transform 0.15s ease-in-out;
    height: 100%;

    &__link {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        text-decoration: none;
    }

    &__media {
        overflow: hidden;
        position: relative;
        width: 42%;
    }

    &__image-container {
        &::after {
            display: block;
            padding-top: calc((10 / 16) * 100%);
            content: '';
        }
    }

    &__image-container--hover {
        transition: 0.15s ease-in-out transform;
    }

    &__heading {
        @include heading-S;
        margin-bottom: 0.8rem;
        color: var(--wideSmallMediaPod-title-color);
    }

    &__summary {
        @include body-small;
        color: var(--wideSmallMediaPod-summary-color);
    }

    &__inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        &.is-reversed {
            flex-direction: row-reverse;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 50%;
        padding: 2.4rem;
        flex-grow: 1;
        @each $size,
            $values
                in (
                    small: (
                        1.6rem,
                        0.8rem
                    ),
                    medium: (
                        2.4rem,
                        1.2rem
                    ),
                    large: (
                        3.2rem,
                        1.6rem
                    )
                )
        {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: nth($values, 1);

                @media (max-width: $break-tablet) {
                    padding: nth($values, 2);
                }
            }
        }
    }

    .o-content-meta {
        margin-bottom: 1rem;
    }

    &__arrow-link-container {
        margin-top: auto;

        .o-arrow-link {
            margin-top: 1.6rem;
        }
    }

    @include hover {
        @include arrow-link-parent-hover;
        transform: scale(1.03);

        #{$this}__heading {
            text-decoration: underline;
        }

        #{$this}__image-container--hover {
            transform: scale(1.03);
        }
    }

    &--no-hover {
        @include hover {
            box-shadow: none;
            transform: scale(1);
        }
    }

    @media (max-width: 1400px) {
        &__inner {
            flex-direction: column;

            &.is-reversed {
                flex-direction: column-reverse;
            }
        }

        &__link {
            height: 100%;
        }

        &__heading {
            @include heading-S;
        }

        &__summary {
            @include body-small;
        }

        &__content {
            flex: 1;
            width: 100%;
        }

        &__media {
            width: 100%;
        }

        .o-content-meta {
            margin-bottom: 0.8rem;
        }

        &__arrow-link-container {
            margin-top: auto;
        }
    }

    @media (max-width: $break-tablet) {
        &__inner {
            flex-direction: row;
        }

        &__media {
            width: 16rem;
        }

        &__content {
            flex: 1;
            flex-direction: column-reverse;
            justify-content: center;
            padding: 1.2rem;
        }

        .o-content-meta {
            margin: 0;
        }

        &__summary {
            display: none;
        }

        &__arrow-link-container {
            display: none;
        }
    }
}
