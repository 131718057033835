// _object-fit-cover-picture.imageSrcClass
// Styles for: object fit cover picture
// Notes: object fit cover picture style for all devices

.object-fit-cover-picture {
    $this: &;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &__img {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
    }

    &__img--top {
        object-position: top;
    }

    &--is-portrait {
        #{$this}__img {
            left: 50%;
            transform: translateX(-50%);
            width: auto;
        }
    }
}
