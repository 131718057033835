.o-mc-stats-overview-header {
    &__overview {
        align-items: center;
        display: flex;
        gap: 1.6rem;
        justify-content: space-between;
        margin-bottom: 0;
        position: relative;
        text-transform: uppercase;
    }

    &__team-container {
        align-items: center;
        display: flex;
        gap: 1.2rem;
        font-weight: $bold;
        justify-content: center;
        width: calc(1/3 * 100%);
    }

    &__team-logo {
        display: flex;
        width: 6.4rem;
    }

    &__heading {
        @include heading-S;
        text-align: center;
        width: calc(1/3 * 100%);
    }

    @media (max-width: $break-phablet) {
        &__overview {
            margin-bottom: 0;
        }

        &__team-container {

            &:first-child {
                padding-right: 1.2rem;
            }

            &:last-child {
                padding-left: 1.2rem;
            }
        }

        &__team-logo {
            width: 5rem;
        }

        &__heading {
            font-size: var(--c-title-xsmall-desktop);
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            width: 50%;
        }
    }
}