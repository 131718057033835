// Styles for: animations
// Notes: animation style for all devices

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes progress {
    0% {
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes skeleton-loading {
    0% {
        background-color: $grey-200;
    }

    50% {
        background-color: $grey-100;
    }

    100% {
        background-color: $grey-200;
    }
}

@keyframes grow {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes expand-width {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
