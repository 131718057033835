:root {
    --page-header-nav: var(--g-component-background);
    --page-header-nav-links: var(--g-color-system-dark);
    --page-header-nav-active-colour: var(--g-color-brand-primary);
    --page-header-nav-hover-colour: var(--g-color-system-mid);
    --mc-active-tab-color: var(--g-color-system-dark);
}

.page-nav-wrapper {
    background: var(--page-header-nav);
    border-radius: var(--g-layout-border-radius);
    box-shadow: var(--c-box-shadow-default);
    display: inline-block;

    &__container {
        @include wrapper(false);
        padding: 0;
        display: flex;
    }

    &--full-width {
        display: flex; // forces outer container to be full-width
        border-radius: 0; // cancel border radius even when global setting is on when nav bar is full-width
    }

    &--child {
        background: none;
        box-shadow: none;
        display: flex;
        justify-content: center;
    }
    
    &--child & {

        &__container {
            width: auto;
        }
    }
}

.page-nav {
    $this: &;
    display: inline-block;

    &__list {
        display: flex;
    }

    &__link {
        cursor: pointer;
        color: var(--page-header-nav-links);
        display: block;
        @include heading-XS;
        padding: 1.4rem 2.4rem;
        position: relative;
        text-decoration: none;
        white-space: nowrap;

        &.is-active,
        &:hover {
            &::before {
                background: var(--page-header-nav-active-colour);
                bottom: 0;
                content: '';
                display: block;
                height: 0.2rem;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        .is-active::before {
            background: var(-page-header-nav-active-colour);
        }

        &:hover::before {
            background: var(--page-header-nav-hover-colour);
        }
    }

    &__link--full-width {
        padding: 2.2rem 2.4rem;
    }

    &__item {
        border-inline-start: 1px solid rgba($black, 0.05);
        display: flex;

        &:first-child {
            border-inline-start: 0;
        }

        &:last-child {
            border-inline-end: 0;
        }
    }

    &__item--hidden {
        border: 0;
        overflow: hidden;
        width: 0;
    }

    &--child & {

        &__list {
            background-color: $grey-200;
        }

        &__link {

            &.is-active {
                background-color: var(--mc-active-tab-color);
                color: var(--c-color-white);

                &::before {
                    display: none;
                }
            }
        }
    }

    @media (max-width: $break-phablet) {
        &__link {
            padding: 1.2rem 1.6rem;
        }

        &__link--full-width {
            padding: 1.6rem 2rem;
        }
    }
}

.page-nav-more {
    $this: &;
    display: inline-block;
    position: relative;

    &--full-width {
        margin-inline-start: auto;
    }

    &--active {
        #{$this}__list-container {
            display: block;
        }
        #{$this}__toggle {
            &::after {
                transform: rotate(-180deg);
            }
        }
    }

    &__list-container {
        background: var(--page-header-nav);
        box-shadow: -2px 0 20px 0 rgba($black, 0.1);
        display: none;
        min-width: 230%;
        padding: 0.8rem;
        position: absolute;
        right: 0;
    }

    &__list {
        max-height: 32rem;
        overflow: auto;
    }

    &__list-container--floating {
        top: calc(100% + 1.6rem);

        &::after {
            border-inline-start: 17px solid transparent;
            border-inline-end: 17px solid transparent;
            border-bottom: 20px solid var(--page-header-nav);
            content: '';
            display: block;
            height: 0;
            position: absolute;
            right: 3.3rem;
            width: 0;
            top: -1.2rem;
        }
    }

    &__link {
        color: var(--page-header-nav-links);
        display: block;
        font-size: 1.4rem;
        font-weight: $bold;
        padding: 1.2rem;
        position: relative;
        text-decoration: none;
        white-space: nowrap;

        &:hover,
        &.is-active {
            #{$this}__text:after {
                display: block;
            }
        }
    }

    &__text {
        display: inline-block;
        padding-bottom: 0.5rem;
        position: relative;

        &::after {
            background: var(--page-nav-active-colour);
            bottom: 0;
            content: '';
            display: none;
            height: 0.2rem;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }

    &__toggle {
        align-items: center;
        border-inline-start: 1px solid rgba($black, 0.05);
        display: flex;
        outline: 0;
        @extend .page-nav__link; // stylelint-disable-line scss/at-extend-no-missing-placeholder

        &::after {
            border-inline-start: 4px solid transparent;
            border-inline-end: 4px solid transparent;
            border-top: 5px solid var(--page-header-nav-links);
            content: '';
            display: inline-block;
            height: 0;
            margin-inline-start: 0.8rem;
            transition: 0.15s ease-in-out transform;
            width: 0;
        }
    }

    &__toggle--full-width {
        background: rgba($black, 0.05);
        border: 0;
        padding: 2.2rem 2.4rem;
        transition: 0.3s ease-in-out background;

        &:hover {
            background: rgba($black, 0.1);

            &::before {
                display: none;
            }
        }
    }

    @media (max-width: $break-phablet) {
        &__toggle {
            padding: 1.2rem 2rem;
        }

        &__toggle--full-width {
            padding: 1.6rem 2rem;
        }
    }
}
