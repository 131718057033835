// PROGRESS
.progress {
    width: 100%;
    height: 0.4rem;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    z-index: $level-one; // ensure bar sits above navigation content
    pointer-events: none;
    overflow: hidden; // avoid progress bar extending beyond page width

    &-bar {
        display: block;
        width: 0;
        height: 100%;
        background: var(--article-progressBar-backgroundColor);
        transition: width ease 0.2s;
    }
}
