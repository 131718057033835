/* stylelint-disable selector-max-attribute  */

/* Grid List */

/* 
  Iterates up to the set limit, generating grid list classes for each integer, 
  using that integer to determine the grid columns 
*/
@mixin generate-classes($breakpoint: null, $limit: 12) {
    $i: 1;

    @while $i <= $limit {
        $class: '.grid-list-#{$i}';
        @if $breakpoint {
            $class: '#{$class}-#{$breakpoint}';
        }

        #{$class} {
            grid-template-columns: repeat(#{$i}, 1fr);
        }

        $i: $i + 1;
    }
}

[class*='grid-list'] {
    display: grid;
    gap: var(--card-gutter);

    li {
        display: grid;
    }

    // For a card that has the grid featured class, set to occupy the space of two columns and two rows
    @media (min-width: $break-desktop) {
        .grid-featured {
            grid-area: 1 / 1 / 3 / 3;
        }
    }
}

@include generate-classes;

@media (max-width: $break-wide) {
    @include generate-classes('wide', 6);
}

@media (max-width: $break-desktop) {
    @include generate-classes('desktop', 4);
}

@media (max-width: $break-tablet) {
    @include generate-classes('tablet', 4);
}

@media (max-width: $break-phablet) {
    [class*='grid-list'] {
        gap: var(--card-gutter);
    }

    @include generate-classes('phablet', 4);
}

@media (max-width: $break-mobile) {
    @include generate-classes('mobile', 4);
}
