.player-bio {
    max-width: 85rem;
    padding-bottom: 4rem;
    @include body-large;

    &__title {
        text-transform: uppercase;
        margin-bottom: 1.6rem;
    }

    &__content {
        p {
            margin-bottom: 1.6rem;;
        }
    }

    @media (max-width: $break-desktop) {
        margin: 0 1.6rem;
    }

    @media (max-width: $break-phablet) {
        width: calc(100% - 3.2rem);
        padding-bottom: 0;
        margin-top: .5rem;
        order: 6;
        @include text-copy;
    }
}