// Styles for: Match Centre Header
// Notes: Match Centre Header includes top level info on the match
// Notes: Date, time, location, teams, scores

:root {
    --mc-header-background-color: var(--g-color-system-dark);
    --mc-header-text-color: var(--c-color-white);
    --mc-own-goal-icon-color: #ff0000;
    --mc-red-card-icon-color: #ff0000;
    --mc-score-box-color: var(--g-color-brand-primary);
}

.w-mc-header {
    $this: &;

    display: flex;
    flex-direction: column;
    background-color: var(--mc-header-background-color);
    color: var(--mc-header-text-color);
    padding-bottom: 4rem;

    &__meta {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .8rem;
        flex-wrap: wrap;
        padding: 1.6rem;
        margin: 0;
        position: relative;
        z-index: $level-one;
    }

    &__meta-item {
        display: flex;
        gap: .8rem;
        align-items: center;
        font-size: var(--c-text-xsmall-desktop);

        &::after {
            content: "";
            display: inline-block;
            width: .5rem;
            height: .5rem;
            vertical-align: middle;
            border-radius: 50%;
            background-color: var(--mc-header-text-color);
        }

        &:last-child {

            &::after {
                display: none;
            }
        }
    }

    &__info-icon {
        width: 1.6rem;
        height: 1.6rem;
        fill: var(--c-color-white);
    }

    &__teams {
        padding: 3rem 0 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.6rem;
    }

    &__team {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.6rem;
        width: 50%;
        animation: fade .4s ease-in-out;
        animation-delay: .2s;
        animation-fill-mode: backwards;

        img {
            width: 6.2rem;
            height: auto;
        }

        &--away {
            justify-content: flex-start
        }
    }

    &__team-name {
        @include heading-M;
        text-transform: uppercase;
    }

    &__kick-off-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 .8rem;
        padding: 0 2rem;
        background-color: rgba($white, 0.2);
        line-height: 6rem;
    }

    &__kick-off-label {
        font-size: var(--c-title-medium-desktop);
        text-transform: uppercase;
        display: flex;
        align-items: center;

        &-time {
            height: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &--tbc {
                @include heading-M;
            }
        }
    }

    &__score-container {
        display: flex;
        flex-direction: column;
        position: relative;
        gap: .4rem;
        width: 16rem;
    }

    &__scorebox {
        display: flex;
        justify-content: center;
        gap: .4rem;
        position: relative;
    }

    &__score {
        background-color: var(--mc-score-box-color);
        color: var(--mc-header-text-color);
        width: 6rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &--lost {
            background-color: rgba($white, 0.1);
        }
    }

    &__score-number {
        @include heading-M;
    }

    &__events {
        padding-bottom: .8rem;
        display: flex;
        gap: 3.2rem;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        animation: fade .4s ease-in-out;
        animation-delay: .3s;
        animation-fill-mode: backwards;
    }

    &__ht-score {
        width: 21rem;
        @include heading-XS;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-transform: uppercase;

        &-label {
            @include meta-2;
        }
    }

    &__current-minute {
        @include heading-S;
        text-transform: uppercase;
        color: var(--g-color-chromatic-emberglow);
    }

    &__team-events {
        display: flex;
        flex-direction: column;
        gap: .8rem;
        justify-content: flex-end;
        align-items: flex-end;
        width: 50%;

        &--home {

            #{$this}__assist {
                text-align: right;
            }
        }

        &--away {
            align-items: flex-start;

            #{$this}__event-players {
                align-items: flex-start;
            }

            #{$this}__event {
                flex-direction: row-reverse;
            }

            #{$this}__assists,
            #{$this}__scorer-container {
                align-items: center;
            }
        }
    }

    &__event {
        display: flex;
        gap: .8rem;
    }

    &__event-icon {
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        display: block;
        fill: $white;

        &--football-og {
            fill: var(--mc-own-goal-icon-color);
        }

        &--card-red {
            fill: var(--mc-red-card-icon-color);
        }
    }

    &__event-players {
        display: flex;
        flex-direction: column;
        gap: 0;
        align-items: flex-end;
    }

    &__players {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }

    &__scorer-container {
        display: flex;
        gap: .8rem;
        align-items: center;
        color: var(--mc-header-text-color);
        text-decoration: none;

        span {
            flex-shrink: 0;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__scorer-name {
        @include body-small;
        font-weight: $bold;
    }

    &__scorer-time {
        @include meta-2;
    }

    &__assists {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: .4rem;
    }

    &__assist {
        display: flex;
        flex-direction: row;
    }

    &__assist-label {
        flex-shrink: 0;
    }

    @media (max-width: $break-tablet) {

        padding-bottom: 2rem;

        &__meta-item {
            font-size: var(--c-text-small-mobile);
        }

        &__teams {
            padding: 2rem 0;
        }

        &__team {

            img {
                width: 3.6rem;
            }
        }

        &__team-name {
            @include heading-XS;
        }

        &__kick-off-container {
            margin: 0 .6rem;
        }

        &__kick-off-label {
            font-size: var(--c-title-small-desktop);

            &-time {
                width: 4rem;
                height: 4rem;
            }

            &-divide {
                @include heading-XS;
            }

            &--tbc {
                @include heading-S;
            }
        }

        &__score-container {
            width: 11rem;
        }

        &__score {
            width: 4rem;
            height: 4rem;
        }

        &__score-number {
            @include heading-S;
        }

        &__events {
            flex-wrap: wrap;
            column-gap: 3.2rem;
            row-gap: 2.4rem;
        }

        &__ht-score {
            width: 100%;
            order: -1;
        }

        &__team-events {
            width: calc((100% - 4.4rem) / 2);
        }

        &__event-icon {
            width: 1.2rem;
            height: 1.2rem;
            margin-top: .4rem;
        }

        &__kick-off-container {
            margin: .8rem 0;
        }

        &__kick-off-label {

            &-divide {
                @include heading-S;
            }

            &-time {
                width: 6.4rem;
                height: 6.4rem;
                @include heading-S;
            }
        }
    }

    @media (max-width: $break-phablet) {

        &__meta {
            width: 100%;
            flex-direction: column-reverse;
            gap: .8rem;
            order: 10;
            margin: 0;
            padding: 1.2rem;
            border-bottom: none;
            border-top: .1rem solid rgba($white, 0.1);
        }

        &__meta-item::after {
            display: none;
        }

        &__teams {
            gap: .8rem;
        }

        &__kick-off-label {

            &-time {
                width: 3.2rem;
                height: 3.2rem;
            }
        }

        &__score {
            width: 3.2rem;
            height: 3.2rem;
        }

        &__score-number {
            @include heading-S;
        }

        &__events {
            column-gap: 1.6rem;
            row-gap: 1.6rem;
        }

        &__team-events {
            gap: 1rem;
        }

        &__scorer-container {
            align-items: flex-start;

            span {
                flex-shrink: 1;
                line-height: 1;
                text-align: right;
            }
        }

        &__event-icon {
            margin-top: 0;
        }
    }
}