:root {
    --football-player-card-font-color: var(--c-color-white);
    --football-player-card-background-color: var(--g-color-brand-primary);
}

.w-football-player-card {
    $this: &;
    display: flex;
    flex-direction: column;

    &__top-section {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: calc(100% - 5.2rem);
        position: relative;
        color: var(--football-player-card-font-color);
        overflow: hidden;
        background-color: var(--football-player-card-background-color);
        background-image: url($img-dir + '/elements/football.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__link {
        height: 100%;
        width: 100%;
        aspect-ratio: 3/4;
        border-radius: var(--border-radius);
        overflow: hidden;
        transition: all 0.3s ease;

        &:hover {
            box-shadow: $box-shadow-default;
        }
    }

    &:link,
    *:link {
        text-decoration: none;
    }

    &__info-container {
        padding: 1.6rem;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: linear-gradient(180deg, rgb(19 25 36 / 0%) 0%, #131924 80%);
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__image-container {
        align-self: flex-end;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &.loaded {
            opacity: 1;
        }

        &--jpg.loaded {
            ~ #{$this}__image--png {
                display: none !important;
            }
        }
    }

    &__number {
        @include heading-XL;
        line-height: 1;
        margin-bottom: 0.4rem;
        opacity: 0.64;
    }

    &__first-name {
        @include heading-XS;
        line-height: 1;
        margin-bottom: 0.6rem;
    }

    &__last-name {
        @include heading-M;
        line-height: 1;
    }

    &__bottom-section {
        padding: 1.2rem 1.6rem;
        height: 5.2rem;
        display: flex;
        align-items: center;
        background-color: var(--football-player-card-background-color);
        color: var(--football-player-card-font-color);
        @include heading-XS;
        gap: 0.4rem;
    }

    &__position {
        margin-top: 0.4rem;
    }

    @media (max-width: $break-desktop) {
        &__number {
            @include heading-M;
        }

        &__first-name {
            @include heading-XS;
        }

        &__last-name {
            font-size: 1.8rem;
        }
    }

    @media (max-width: $break-phablet) {
        &__top-section {
            height: calc(100% - 3.4rem);
        }

        &__info-container {
            padding: 0.8rem;
        }

        &__number {
            @include heading-M;
            margin-bottom: 0;
        }

        &__first-name {
            @include heading-XS;
            margin-bottom: 0.4rem;
        }

        &__last-name {
            font-size: 1.8rem;
        }

        &__bottom-section {
            height: 3.4rem;
            @include heading-XS;
            padding: 0.8rem;
        }

        &__position {
            margin-top: 0.2rem;
        }
    }
}
