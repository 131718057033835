// Styles for: Filter dropdown

// CONFIG

:root {
    --filter-backgroundColor: var(--g-color-system-light);
    --filter-textColor: var(--g-color-system-dark);
    --filter-resetTextColour: var(--g-typography-color-widget-component);
}

.filter {
    $this: &;
    box-shadow: 2px 4px 10px rgba($black, 0.1);
    height: fit-content;
    position: relative;
    width: 20rem;
    background: var(--filter-backgroundColor);
    color: var(--filter-textColor);

    &::after {
        content: '';
        display: block;
        border-inline-start: 6px solid transparent;
        border-inline-end: 6px solid transparent;
        border-top: 6px solid var(--filter-textColor);
        height: 0;
        pointer-events: none;
        position: absolute;
        right: 1.4rem;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.25s ease-in-out transform;
        width: 0;
    }

    &__selected {
        border: 0;
        display: block;
        font-size: var(--c-title-xsmall-desktop);
        height: 5.6rem;
        min-width: 20rem;
        outline: 0;
        padding: 0 1.2rem;
        text-align: left;
    }

    &__description {
        @include heading-M;
        display: block;
        font-size: 1rem;
        font-weight: $regular;
        margin-bottom: 0.4rem;
    }

    &__list {
        display: none;
        background: var(--filter-backgroundColor);
        box-shadow: 2px 4px 10px rgb(22 22 22 / 10%);
        margin: 0;
        padding: 0.8rem;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: $level-two;
    }

    &__item {
        font-size: var(--c-title-xsmall-desktop);
        height: 4.4rem;
        outline: 0;
        width: 100%;

        &--active,
        &:hover {
            // colours are flipped colour from text & bg colours on hover and/or active state
            background: var(--filter-textColor);
            color: var(--filter-backgroundColor);
        }
    }

    &.active {
        @media (min-width: $break-phablet) {
            #{$this}__list {
                display: block;
            }
        }

        &::after {
            transform: translateY(-50%) rotate(-180deg);
        }
    }

    @media (max-width: $break-phablet) {
        $mobile-filter-gutter: 2.4rem;
        box-shadow: none;
        width: 4rem;

        &::after {
            display: none;
        }

        &__header {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            font-size: 2rem;
            font-weight: $bold;
            padding: 1.6rem $mobile-filter-gutter;
            text-transform: uppercase;
            border-bottom: 2px solid rgba($black, 0.1);
        }

        &__modal-close {
            margin-left: auto;
            padding: 1rem;

            .icon {
                width: 1.8rem;
                height: 1.8rem;
            }
        }

        &__title {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }

        &__description {
            padding: 2.4rem $mobile-filter-gutter;
        }

        &__selected {
            background: rgb(26 82 193 / 20%);
            height: 4rem;
            width: 4rem;
            min-width: 4rem;
            padding: 0;
            position: relative;
            text-align: center;

            &-icon {
                display: inline-block;
                fill: $color-primary;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &__list-container {
            position: fixed;
            transform: translateY(
                100%
            ); // position offscreen by default - bring into view with .active class
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $white;
            z-index: $level-six;
            transition: transform 0.2s;
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0.8rem;
            position: relative;
            top: 0;
            box-shadow: none;
            padding: 0 $mobile-filter-gutter;
        }

        &__item {
            box-shadow: 2px 4px 10px rgba($black, 0.1);
        }

        &__mobile-controls {
            width: 100%;
            background-color: $white;
            padding: 2.4rem $mobile-filter-gutter;
            box-shadow: 2px -4px 10px rgba($black, 0.1);
            margin-top: auto;
        }

        &__mobile-controls-list {
            overflow: auto;
            display: flex;
            gap: 0.8rem;

            &-item {
                flex: 1;
            }
        }

        &__mobile-controls-btn {
            background: var(--g-color-brand-primary);
            border-radius: 0.4rem;
            color: $white;
            font-size: 1.4rem;
            font-weight: $bold;
            height: 4.4rem;
            width: 100%;

            &--reset {
                background: rgb(26 82 193 / 20%);
                color: var(--g-color-brand-primary);
            }
        }

        &.active {
            #{$this}__list-container {
                transform: translateY(0);
            }
        }
    }
}
