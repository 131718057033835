// Styles for: Media pod
// Notes: Media pod style for all devices

// CONFIG

:root {
    --mediaPod-root-backgroundColor: var(--g-component-background);
    --mediaPod-root-boxShadow: var(--g-layout-box-shadow);
    --mediaPod-root-border: var(--c-border-none);
    --mediaPod-root-borderRadius: var(--g-layout-border-radius);
    --mediaPod-brandBar-background: var(--g-color-brand-primary);
    --mediaPod-title-color: var(--g-color-system-dark);
    --mediaPod-summary-color: var(--g-color-system-dark);
    --mediaPod-arrowLink-color: var(--g-button-ghost-color);
}

// MEDIA POD

.o-media-pod {
    $this: &;
    display: flex;
    flex: 1 1 0;
    width: 100%;
    border: var(--mediaPod-root-border);
    border-radius: var(--mediaPod-root-borderRadius);
    overflow: hidden;
    background-color: var(--mediaPod-root-backgroundColor);
    box-shadow: var(--mediaPod-root-boxShadow);
    transition: transform 0.15s ease-in-out;

    &__brand-bar {
        height: 0.8rem;
        border: none;
        background: var(--mediaPod-brandBar-background);
    }

    &__link {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-decoration: none;
    }

    &__image-container {
        &::after {
            display: block;
            padding-top: calc((10 / 16) * 100%);
            content: '';
        }
    }

    &__image {
        &--youtube::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(360deg, $black 0%, transparent 50%);
        }
    }

    &__heading {
        @include heading-S;
        @include truncate-multiline(2, 1.25);
        line-height: 1.25;
        color: var(--mediaPod-title-color);
    }

    &__summary {
        @include body-small;
        line-height: 1.5;
        @include truncate-multiline(2, 1.5);
        color: var(--mediaPod-summary-color);
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &__content {
        display: flex;
        flex: 1 0 0;
        flex-basis: auto;
        flex-direction: column;
        gap: 0.8rem;
        padding: $spacing-l;
        @each $size,
            $values
                in (
                    small: (
                        1.6rem,
                        0.8rem
                    ),
                    medium: (
                        2.4rem,
                        1.2rem
                    ),
                    large: (
                        3.2rem,
                        1.6rem
                    )
                )
        {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: nth($values, 1);

                @media (max-width: $break-tablet) {
                    padding: nth($values, 2);
                }
            }
        }
    }

    &__arrow-link-container {
        margin-top: auto;

        .o-arrow-link {
            margin-top: 0.8rem; // combined with gap to create 1.6rem spacing
            color: var(--mediaPod-arrowLink-color);

            svg {
                fill: var(--mediaPod-arrowLink-color);
            }
        }
    }

    &__video-duration,
    &__gallery-size {
        z-index: $level-one;
        position: absolute;
        bottom: 1.6rem;
        left: 1.6rem;
        font-weight: $bold;
    }

    &__video-play {
        z-index: $level-one;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7.2rem;
        height: 7.2rem;
        opacity: 0.95;
        border-radius: 50%;
        background: rgba($black, 0.5);
        transition: all 0.25s ease-in-out;
        transform: translate(-50%, -50%);
    }

    &__video-play-icn {
        fill: $white;
        position: absolute;
        width: 100%;
        height: 80%;
        margin: auto;
        inset: 0;
    }

    &__youtube-logo {
        z-index: $level-one;
        position: absolute;
        bottom: $spacing-m;
        left: $spacing-m;
        width: 9rem;
        height: 2.1rem;
    }

    &__youtube-icon {
        width: 100%;
        height: 100%;
        fill: $white;
    }

    &__gallery-grid-container {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__gallery-grid {
        display: flex;
        flex-wrap: wrap;
        height: 105%;
        width: 105%;
        flex-shrink: 0;
    }

    &__gallery-grid-item {
        border: 1px solid $white;
        display: flex;
        position: relative;
        width: 50%;

        /* stylelint-disable-next-line selector-max-universal */
        > * {
            flex: 1;
        }
    }

    &--rounded {
        border-radius: 0.5rem;
        overflow: hidden;
    }

    @include hover {
        @include arrow-link-parent-hover;
        transform: scale(1.03);

        #{$this}__heading {
            text-decoration: underline;
        }
    }

    @media (max-width: $break-phablet) {
        &__image {
            &--youtube {
                overflow: hidden;

                /* 
                * when sddefault is used for pahblet MQ the image has black bars on top and bottom
                * this way we hide black bars
                */
                img {
                    height: calc(100% + 12rem);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        &__youtube-logo {
            bottom: $spacing-xs;
            left: $spacing-xs;
            width: 6.6rem;
            height: 1.5rem;
        }

        &--small-thumbnail-phablet {
            #{$this}__inner {
                flex-direction: row;
            }

            #{$this}__link {
                flex-grow: 1;
            }

            #{$this}__content {
                flex: 1;
                justify-content: flex-end;
                padding: 1.2rem;
                flex-direction: column-reverse;
                max-width: 50%;
            }

            #{$this}__header {
                width: 50%;
            }

            #{$this}__image-container {
                width: 100%;

                &::after {
                    padding-top: calc((10 / 16) * 100%);
                }
            }

            #{$this}__video-duration {
                bottom: 0.8rem;
                left: 0.8rem;
            }

            #{$this}__video-play {
                width: 4rem;
                height: 4rem;
            }

            #{$this}__summary {
                display: none;
            }

            #{$this}__arrow-link-container {
                display: none;
            }
        }

        &__content {
            padding: $layout-padding;
        }

        &__heading {
            @include heading-S;
            line-height: 1.25;
            @include truncate-multiline(3, 1.25);
        }

        &__summary {
            @include truncate-multiline(2, 1.4);
        }

        &__video-duration,
        &__gallery-size {
            bottom: 0.5rem;
            left: 0.5rem;
        }

        &__video-play-icn--small-phablet {
            height: 3.6rem;
            width: 3.6rem;
        }

        .o-arrow-link {
            margin-top: 0.8rem;
        }
    }

    @media (min-width: $break-desktop) {
        .grid-featured & {
            &__heading {
                @include heading-L;
                margin-bottom: 1.6rem;
            }

            &__content {
                padding: 4rem;
            }

            &__summary {
                @include body-large;
            }

            // Style button as a primary button when a featured card
            .o-arrow-link {
                margin-top: 1.6rem;
                color: var(--g-button-primary-color);
                background: var(--g-button-primary-background);
                border: 0.2rem solid var(--g-button-primary-border-color);
                padding: var(--g-layout-button-spacing);
                height: 4.4rem;
                border-radius: var(--g-button-border-radius);
                box-shadow: var(--g-layout-box-shadow);

                svg {
                    fill: var(--g-button-primary-color);
                }
            }

            &__video-play {
                height: 10.2rem;
                width: 10.2rem;
            }
        }
    }
}
