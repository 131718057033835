// Styles for: Variables
// Notes: All site variables and settings

/* Base Font Size */
$pixel-base: 10;

/* Core */
$black: #000;
$white: #fff;
$mid: #f5f5f5;
$correct: #408428;
$error: #b40000;
$transparent: transparent;

/* Greys */
$grey-100: #fafafa;
$grey-200: #f5f5f5;
$grey-300: #eee;
$grey-400: #c5c5c5;
$grey-500: #9e9e9e;
$grey-600: #7f7f7f;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #262626;

/* Brand Colours */
$color-primary: #1a52c1;
$color-secondary: #0e3177;
$color-accent: #ff1f1f;
$color-dark: #161616;
$color-mid: #f5f5f5;
$color-light: #fff;
$color-live: #40f99b;

/* Match Colours */
$red: #f00;
$yellow: #f6d000;

/* Text */
$text-heading: $color-dark;
$text-copy: $color-dark;

/* Images */
$img-dir: '../i';

/* Breakpoints */
$break-mobile: 400px;
$break-phablet: 640px;
$break-tablet: 840px;
$break-desktop: 1025px;
$break-wide: 1300px;

/* Z-Index Layers */
$level-negative: -10;
$level-one: 10;
$level-two: 20;
$level-three: 30;
$level-four: 40;
$level-five: 50;
$level-six: 60;
$level-top: 200;

/* Spacing */
$spacing-xxs: 0.4rem;
$spacing-xs: 0.8rem;
$spacing-s: 1.2rem;
$spacing-m: 1.6rem;
$spacing-l: 2.4rem;
$spacing-xl: 3.2rem;
$spacing-xxl: 6.4rem;
$spacing-xxxl: 12.8rem;

/* Layout */
$layout-padding: $spacing-s;
$wrapper-width: 137.6rem;
$wrapper-max-width: 192rem;
$content-max-width: 84.5rem;

/* Box Shadows */
$box-shadow-default: 0 0 2rem 0 rgba($black, 0.50);

/* Border */
$border-color-inverted-color: #c9ced633;

/* Widget Spacing */
$widget-spacing-desktop-small: 1.6rem;
$widget-spacing-desktop-medium: 5.6rem;
$widget-spacing-desktop-large: 9.6rem;
$widget-spacing-tablet-small: 1.6rem;
$widget-spacing-tablet-medium: 3.2rem;
$widget-spacing-tablet-large: 5.6rem;
$widget-spacing-mobile-small: 1.6rem;
$widget-spacing-mobile-medium: 3.2rem;
$widget-spacing-mobile-large: 5.6rem;

/* Font Weights */
$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$fw-black: 900;
$font-display: swap;

:root {
    // CORE
    --c-color-transparent: #{$transparent};
    --c-color-black: #{$black};
    --c-color-white: #{$white};
    --c-box-shadow-default: 0.2rem 0.8rem 2rem 0 rgb(22 22 22 / 10%);
    --c-box-shadow-none: none;
    --c-popout-shadow-default: -0.4rem 0.2rem 2rem 0 rgb(0 0 0 / 20%);
    --c-dropdown-shadow-default: 0 1.4rem 1.5rem 0 rgb(0 0 0 / 20%);
    --c-dark-button-overlay: rgb(0 0 0 / 10%);
    --c-light-button-overlay: rgb(225 225 225 / 20%);
    --c-text-underline-off: none;
    --c-text-underline-on: underline;
    --c-rounded-corners-off: 0;
    --c-rounded-corners-on: 0.8rem;
    --c-widget-spacing-small: 4rem;
    --c-widget-spacing-medium: 5.6rem;
    --c-widget-spacing-large: 8rem;

    // These should have a '--c' prefix really, but might be a pain to update as these values already exist in the DB
    --g-button-border-square: 0;
    --g-button-border-rounded: 0.8rem;
    --g-button-border-circular: 8rem;
    --c-border-none: none;
    --c-button-spacing: 0 1.6rem;

    // FONTS
    --c-systemfont-arial: 'Arial';
    --c-systemfont-helvetica: 'Helvetica';
    --c-systemfont-tahoma: 'Tahoma';

    // GOOGLE FONTS
    --c-font-anton: 'Anton';
    --c-font-archivo: 'Archivo';
    --c-font-barlow: 'Barlow';
    --c-font-chivo: 'Chivo';
    --c-font-epilogue: 'Epilogue';
    --c-font-inter: 'Inter';
    --c-font-inter-tight: 'Inter Tight';
    --c-font-lato: 'Lato';
    --c-font-lora: 'Lora';
    --c-font-manrope: 'Manrope';
    --c-font-montserrat: 'Montserrat';
    --c-font-noto-sans: 'Noto Sans';
    --c-font-open-sans: 'Open Sans';
    --c-font-oswald: 'Oswald';
    --c-font-poppins: 'Poppins';
    --c-font-roboto: 'Roboto';
    --c-font-sora: 'Sora';
    --c-font-source-sans-3: 'Source Sans';
    --c-font-space-grotesk: 'Space Grotesk';
    --c-font-urbanist: 'Urbanist';

    // DESKTOP FONT SIZES
    --c-title-xxlarge-desktop: 6.4rem;
    --c-title-xlarge-desktop: 4.8rem;
    --c-title-large-desktop: 4rem;
    --c-title-medium-desktop: 3.2rem;
    --c-title-small-desktop: 2rem;
    --c-title-xsmall-desktop: 1.6rem;
    --c-title-xxsmall-desktop: 1.2rem;
    --c-text-large-desktop: 2rem;
    --c-text-medium-desktop: 1.6rem;
    --c-text-small-desktop: 1.4rem;
    --c-text-xsmall-desktop: 1.2rem;

    // MOBILE FONT SIZES
    --c-title-xxlarge-mobile: 3.2rem;
    --c-title-xlarge-mobile: 2.8rem;
    --c-title-large-mobile: 2.4rem;
    --c-title-medium-mobile: 2rem;
    --c-title-small-mobile: 1.6rem;
    --c-title-xsmall-mobile: 1.2rem;
    --c-text-large-mobile: 1.8rem;
    --c-text-medium-mobile: 1.6rem;
    --c-text-small-mobile: 1.2rem;

    // GLOBAL
    // ==========================================

    --g-style-rounded-corners: var(--c-rounded-corners-off);
    --g-style-widget-spacing: var(--c-widget-spacing-medium);
    --g-style-box-shadows: var(--c-box-shadow-none);

    // COLOURS

    // BRAND
    --g-color-brand-primary: #{$color-primary};
    --g-color-brand-secondary: #{$color-secondary};
    --g-color-brand-accent: #{$color-accent};

    --g-color-football-win: #{$correct};
    --g-color-football-lose: #{$error};
    --g-color-football-draw: #{$grey-400};

    // SYSTEM
    --g-color-system-dark: #{$color-dark};
    --g-color-system-mid: #{$color-mid};
    --g-color-system-light: #{$color-light};
    --g-color-gradient-1: linear-gradient(
        90deg,
        var(--g-color-brand-primary),
        var(--g-color-brand-accent)
    );

    // BACKGROUND
    --g-website-background: var(--c-color-white);
    --g-widget-background: var(--c-color-transparent);
    --g-component-background: var(--c-color-transparent);

    // TEXT
    --g-typography-primary-typeface: var(--c-font-inter);
    --g-typography-color-heading: var(--g-color-system-dark);
    --g-typography-color-paragraph: var(--g-color-system-dark);
    --g-typography-color-link: var(--g-color-brand-primary);
    --g-typography-color-widget-heading: var(--g-color-system-dark);
    --g-typography-color-widget-component: var(--g-color-system-dark);

    // EMBED COLOURS
    --g-embed-background-color: var(--g-component-background);
    --g-embed-text-color: var(--g-typography-color-widget-component);
    --g-embed-caption-color: var(--g-typography-color-widget-component);

    // LAYOUT
    // ==========================================
    --g-layout-box-shadow: var(--g-style-box-shadows);
    --g-layout-button-spacing: var(--c-button-spacing);
    --g-layout-border-radius: var(--g-style-rounded-corners);
    @media (max-width: $break-phablet) {
        --g-layout-border-radius: calc(var(--g-style-rounded-corners) / 2);
    }

    --g-layout-widget-spacing: var(--g-style-widget-spacing);
    @media (max-width: $break-phablet) {
        --g-layout-widget-spacing: calc(var(--g-style-widget-spacing) / 2);
    }

    // BUTTONS
    // ==========================================
    --g-button-border-radius: var(--g-button-border-square);

    // PRIMARY BUTTON
    --g-button-primary-background: var(--g-color-brand-primary);
    --g-button-primary-border-color: var(--c-color-transparent);
    --g-button-primary-color: var(--c-color-white);
    --g-button-primary-hover-overlay: var(--c-dark-button-overlay);

    // SECONDARY BUTTON
    --g-button-secondary-background: var(--c-color-transparent);
    --g-button-secondary-border-color: var(--g-color-brand-primary);
    --g-button-secondary-color: var(--g-color-brand-primary);
    --g-button-secondary-hover-overlay: var(--c-dark-button-overlay);

    // GHOST BUTTON
    --g-button-ghost-color: var(--g-color-brand-primary);
    --g-button-ghost-underline: var(--c-text-underline-off);

    // MISC
    // ==========================================

    // TAGS
    --g-tags-background-color: var(--c-color-transparent);
    --g-tags-text-color: var(--g-color-brand-secondary);
}
