.related-content-thumb {
    $this: &;
    display: flex;
    flex: 1 1 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    isolation: isolate;
    color: var(--g-color-brand-primary);

    &:hover,
    &:active {

        #{$this} {
            &__play-button {
                transform: translate(-50%, -50%) scale(.9);
            }

            &__image-container {
                transform: scale(1.2);
            }
        }
    }


    &__link {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        width: 100%;
        text-decoration: none;
        color: inherit;

        @include link-reset;
        // transition: background .2s ease-out;

    }

    &__header-meta {
        height: 22rem;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }

    &__text-content {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 0.8rem;
    }

    &__header {
        margin-bottom: 1.6rem;
    }

    &__heading {
        font-size: 2rem;
        line-height: 3rem;
        @include truncate-multiline(2, 3rem);
        color: var(--g-typography-color-heading);
    }

    &__subtitle {
        @include truncate-multiline(1, 1.4);
        font-size: 1.4rem;
        line-height: 2rem;
        color: var(--g-typography-color-paragraph);
    }

    &__image-container {
        width: 100%;
        height: 100%;
        position: relative;
        inset: 0;
        transition: transform 1s;

        img {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
    }

    &__meta {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__meta-inner {
        width: fit-content;
        background: var(--g-color-system-dark);
        color: var(--c-color-white);
        font-size: 1.4rem;
        font-weight: $bold;
        padding: .2rem .6rem;
        margin-left: 1.6rem;
        margin-bottom: 1.6rem;
    }

    &__play-button {
        width: 5.4rem;
        height: 5.4rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--g-color-system-dark);
        border-radius: 50%;
        transition: transform .5s;

        &::after {
            content: '';
            display: block;
            width: 4rem;
            height: 4rem;
            background-image: url($img-dir + '/elements/play.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__meta-wrapper {
        display: flex;
        align-items: center;
        gap: 0 .8rem;
    }

    &__tag,
    &__date {
        font-size: 1.2rem;
    }

    &__tag {
        background: var(--g-tags-background-color);
        color: var(--g-tags-text-color);
        text-transform: uppercase;
        padding: .4rem .8rem;
    }

    &__date {
        color: var(--g-typography-color-paragraph);
        opacity: .8;
    }

    @media(max-width: $break-phablet) {

        &__header-meta {
            height: auto;
            aspect-ratio: 16 / 9;
        }

        &__text-content {
            padding: .8rem;
        }

        &__header {
            margin-bottom: .8rem;
        }

        &__heading {
            font-size: 1.4rem;
            line-height: 2rem;
        }

        &__subtitle {
            display: none;
        }

        &__tag {
            font-size: 1rem;
        }

        &__meta-inner {
            font-size: 1.2rem;
            margin-left: 1rem;
            margin-bottom: 1rem;
        }
    }
}
