/* stylelint-disable selector-no-qualifying-type */

@mixin wrapper($with-gutter: true) {
    width: 100%;
    max-width: $wrapper-width;
    margin: 0 auto;

    @if $with-gutter {
        @include widget-gutter;
    }

    @include clearfix;
}

@mixin widget-gutter {
    padding-left: $layout-padding;
    padding-right: $layout-padding;

    @media (max-width: $break-phablet) {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
}

@mixin widget-inner-spacing {
    padding-top: var(--g-layout-widget-spacing);
    padding-bottom: var(--g-layout-widget-spacing);

    @media (max-width: $break-tablet) {
        padding-top: var(--g-layout-widget-spacing);
        padding-bottom: var(--g-layout-widget-spacing);
    }

    @media (max-width: $break-phablet) {
        padding-top: var(--g-layout-widget-spacing);
        padding-bottom: var(--g-layout-widget-spacing);
    }
}

@mixin widget-outer-spacing {
    padding-top: var(--g-layout-widget-spacing);
    padding-bottom: var(--g-layout-widget-spacing);

    @media (max-width: $break-tablet) {
        padding-top: var(--g-layout-widget-spacing);
        padding-bottom: var(--g-layout-widget-spacing);
    }

    @media (max-width: $break-phablet) {
        padding-top: var(--g-layout-widget-spacing);
        padding-bottom: var(--g-layout-widget-spacing);
    }
}

.o-wrapper {
    @include wrapper;
}

.widget-gutter {
    @include widget-gutter;
}

.o-widget-inner-spacing {
    @include widget-inner-spacing;
}

.o-widget-outer-spacing {
    @include widget-outer-spacing;
}

// Card gutter sizes to be used across all grids, lists and carousels
:root {
    --card-gutter: 2.4rem;
    @media (max-width: $break-phablet) {
        --card-gutter: 1.2rem;
    }

    body[data-theme-widget-card-outer-spacing='extra-small'] {
        --card-gutter: 0.2rem;
        @media (max-width: $break-phablet) {
            --card-gutter: 0.1rem;
        }
    }

    body[data-theme-widget-card-outer-spacing='small'] {
        --card-gutter: 0.8rem;
        @media (max-width: $break-phablet) {
            --card-gutter: 0.4rem;
        }
    }

    body[data-theme-widget-card-outer-spacing='medium'] {
        --card-gutter: 1.6rem;
        @media (max-width: $break-phablet) {
            --card-gutter: 0.8rem;
        }
    }

    body[data-theme-widget-card-outer-spacing='large'] {
        --card-gutter: 2.4rem;
        @media (max-width: $break-phablet) {
            --card-gutter: 1.2rem;
        }
    }
}
