// Styles for: Large media pod
// Notes: Large media pod style for all devices

// CONFIG

:root {
    --largeMediaPod-root-backgroundColor: var(--g-component-background);
    --largeMediaPod-root-boxShadow: var(--g-layout-box-shadow);
    --largeMediaPod-root-border: var(--c-border-none);
    --largeMediaPod-root-borderRadius: var(--g-layout-border-radius);
    --largeMediaPod-brandBar-background: var(--g-color-brand-primary);
    --largeMediaPod-title-color: var(--g-color-system-dark);
    --largeMediaPod-summary-color: var(--g-color-system-dark);
    --largeMediaPod-arrowLink-color: var(--g-button-ghost-color);
}

// LARGE MEDIA POD

.o-large-media-pod {
    $this: &;
    display: flex;
    flex: 1 1 0;
    width: 100%;
    border: var(--largeMediaPod-root-border);
    border-radius: var(--largeMediaPod-root-borderRadius);
    overflow: hidden;
    background-color: var(--largeMediaPod-root-backgroundColor);
    box-shadow: var(--largeMediaPod-root-boxShadow);
    transition: transform 0.15s ease-in-out;
    height: 100%;
    position: relative;

    &__link {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-decoration: none;
    }

    &__image-container {
        &::after {
            display: block;
            padding-top: calc((10 / 16) * 100%);
            content: '';
        }
    }

    &__image-container--hover {
        transition: 0.15s ease-in-out transform;
    }

    &__heading {
        @include heading-M;
        margin-bottom: 0.8rem;
        color: var(--largeMediaPod-title-color);
    }

    &__summary {
        @include body-medium;
        color: var(--largeMediaPod-summary-color);
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &__content {
        display: flex;
        flex: 1 0 0;
        flex-basis: auto;
        flex-direction: column;
        padding: $spacing-l;

        @each $size, $values in (small: 1.6rem, medium: 2.4rem, large: 3.2rem) {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: $values;
            }
        }
    }

    .o-content-meta {
        margin-bottom: 1rem;
    }

    &__arrow-link-container {
        margin-top: auto;

        .o-arrow-link {
            color: var(--largeMediaPod-arrowLink-color);

            svg {
                fill: var(--largeMediaPod-arrowLink-color);
            }
        }
    }

    @include hover {
        @include arrow-link-parent-hover;
        transform: scale(1.03);
        z-index: $level-one;

        #{$this}__heading {
            text-decoration: underline;
        }

        #{$this}__image-container--hover {
            transform: scale(1.03);
        }
    }

    &--no-hover {
        @include hover {
            box-shadow: none;
            transform: scale(1);
        }
    }

    @media (max-width: 1400px) {
        &__inner {
            flex-direction: row;
        }

        &__link {
            flex-grow: 1;
        }

        &__content {
            flex: 1;
            justify-content: center;
            padding: 4rem;
        }

        &__header {
            overflow: hidden;
            width: 50%;
        }

        &__image-container {
            width: 100%;

            &::after {
                padding-top: calc((10 / 16) * 100%);
            }
        }

        &__arrow-link-container {
            margin-top: 0;
        }
    }

    @media (max-width: $break-phablet) {
        &__inner {
            flex-direction: column;
        }

        &__header {
            width: 100%;
        }

        &__content {
            padding: 2.4rem;
        }

        &__summary {
            margin-bottom: 2.4rem;
        }

        &__heading {
            margin-bottom: 2.4rem;
        }
    }
}
