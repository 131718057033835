/*
 *
 * Mixin accepts parameters for a map and optional placement
 * Maps are declared in the theme-map.scss file
 * The map paramater takes the name of the map
 * The placement paramater accepts this, parent or both
 * This will apply the theme class so that it will work with it applied to any parent of the element, the element itself or both
 * Inside the target declaration, all of the variables used are set here
 * This will need to be updated if any then keys are added to any of the maps
 * The declaration can be named how you like
 * Maps do not need to have all of these keys
 * After the content is processed all of the variables are reset so that they do not conflict with the outside SASS
 *
 * THIS
 * .element{
 *      @include themify( $example-colors, this ) {
 *         background: $colour-primary;
 *     }
 * }
 *
 * WRAP
 * .element {
 *     @include themify( $example-colors, wrap ) {
 *         background: $colour-primary;
 *     }
 * }
 *
 * OUTPUTS
 *
 * THIS
 * .element.theme-1 {
 *   background: goldenrod;
 * }
 *
 * .element.theme-2 {
 *   background: coral;
 * }
 *
 * WRAP
 * .element .theme-1 {
 *   background: goldenrod;
 * }
 *
 * .element .theme-2 {
 *   background: coral;
 * }
 *
 */

@mixin themify($map, $placement: 'both') {
    @each $theme, $colors in $map {
        $target: '';
        @if $placement == this {
            $target: '&.t-#{$theme}';
        } @else if $placement == wrap {
            $target: '.t-#{$theme} &';
        } @else {
            $target: '&.t-#{$theme}, .t-#{$theme} &';
        }

        #{$target} {
            $color-primary: map-get($colors, 'primary') !global;
            $color-primary-hover: map-get($colors, 'primary-hover') !global;
            $color-secondary: map-get($colors, 'secondary') !global;
            $color-secondary-hover: map-get($colors, 'secondary-hover') !global;
            @content;

            $color-primary: null !global;
            $color-primary-hover: null !global;
            $color-secondary: null !global;
            $color-secondary-hover: null !global;
        }
    }
}
