// Styles for: Custom checkbox
// Notes: Custom checkbox style for all devices

// CONFIG

:root {
    // --videoDuration-tag-backgroundColor: var(--g-color-system-dark);
    // --videoDuration-tag-color: var(--g-color-system-light);
}

// CUSTOM CHECKBOX

.o-custom-checkbox {
    $this: &;

    &__display {
        display: flex;
        align-items: center;
        margin-left: 0.9rem;
    }

    &__label {
        cursor: pointer;
        font-size: 1.4rem;
        font-weight: $bold !important; // stylelint-disable-line declaration-no-important
        letter-spacing: -0.02rem;
        line-height: 1.29;
        padding-right: 3.8rem; // toggle width
        position: relative;
        transition: color 0.25s ease-in-out;
        margin: 0 !important; // stylelint-disable-line declaration-no-important

        &::before {
            background-color: $grey-600;
            border: 1px solid #e6e6e6;
            border-radius: 0.9rem;
            content: '';
            height: 1.4rem;
            position: absolute;
            right: 0;
            top: 50%;
            transition: all 0.25s ease-in-out;
            transform: translateY(-50%);
            width: 3rem;
        }

        &::after {
            background-color: $white;
            border-radius: 100%;
            box-shadow: 0 1px 2px 0 rgba($black, 0.15),
                0 0 2px 0 rgba($black, 0.2);
            content: '';
            height: 1.2rem;
            position: absolute;
            right: 0.2rem;
            top: 50%;
            transition: transform 0.25s ease-in-out;
            transform: translate(-1.4rem, -50%);
            width: 1.2rem;
        }

        @include hover {
            color: $grey-600;
        }
    }

    .betting-logo {
        margin-right: 0.8rem;
        transition: all 0.25s ease-in-out;
    }

    &:not(#{$this}--checked) {
        .betting-logo {
            /* IE6-9 */
            filter: grayscale(1);
            opacity: 0.5;
        }
    }

    &__input {
        width: 0;
        height: 0;
        position: absolute;
        top: 50%;
        right: 1rem;

        &:checked + #{$this}__label {
            font-weight: $bold;

            &::before {
                background-color: var(--g-color-brand-primary);
                border-color: var(--g-color-brand-primary);
            }

            &::after {
                transform: translate(0, -50%);
            }
        }

        &:focus + #{$this}__label {
            text-decoration: none;
        }
    }

    @media (max-width: $break-desktop) {
        &--showbettingodds #{$this} {
            &__label {
                font-size: 1.1rem;
                color: $grey-600;
            }
        }
    }
}
