// Styles for: Brand bar
// Notes: Brand bar style for all devices

// CONFIG

:root {
    --brandBar-background: var(--g-color-gradient-1);
}

.o-brand-bar {
    width: 16rem;
    height: 1.2rem;
    margin: 0;
    border: none;
    background: var(--brandBar-background);

    @media (max-width: $break-phablet) {
        width: 12rem;
        height: 0.8rem;
    }
}
