// Styles for: Content meta
// Notes: Content meta style for all devices

// CONFIG

@use 'sass:math';

:root {
    --contentMeta-tag-backgroundColor: var(--g-tags-background-color);
    --contentMeta-tag-color: var(--g-tags-text-color);
    --contentMeta-text-color: var(--g-typography-color-widget-component);
}

// CONTENT META

@mixin small-version($item-gap, $bullet-width, $row-height) {
    gap: 0 calc($item-gap + $bullet-width);
    height: $row-height;

    .o-content-meta__item {
        font-size: 1rem;
        line-height: $row-height;

        &--tag {
            padding: 0 0.4rem;
        }

        &::before {
            // halves the mobile gap and bullet width to calculate the centre
            left: -(calc(math.div($item-gap, 2) + math.div($bullet-width, 2)));
        }
    }
}

.o-content-meta {
    $this: &;
    $bullet-width: 0.4rem;
    $item-gap: 1.6rem;
    $row-height: 2.4rem;
    $mobile-item-gap: calc($item-gap / 2);
    $mobile-row-height: 1.6rem;
    $meta-text-opacity-dark-bg: 1;
    $meta-text-opacity: 0.5;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 calc($item-gap + $bullet-width);
    height: $row-height;
    overflow: hidden;

    &__item {
        font-size: 1.2rem;
        font-weight: $regular;
        white-space: nowrap;
        line-height: $row-height;
        position: relative;
        display: inline-flex;
        align-items: center;
        gap: calc($item-gap + $bullet-width);

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            left: -(calc(math.div($item-gap, 2) + math.div($bullet-width, 2)));
            background: var(--contentMeta-text-color);
            height: $bullet-width;
            width: $bullet-width;
            border-radius: 5rem;
            opacity: 0.5;
        }

        // Hide bullet before first item
        &:first-child::before {
            content: none;
        }

        &--tag {
            font-weight: $extrabold;
            letter-spacing: -0.02rem;
            background: var(--contentMeta-tag-backgroundColor);
            border-radius: var(--g-layout-border-radius);
            color: var(--contentMeta-tag-color);
            text-align: center;
            text-transform: uppercase;
            padding: 0 0.6rem;

            // If tag is empty ensure nothing renders to avoid an empty 'background'
            &:empty {
                display: none;
            }
        }

        &--text {
            opacity: $meta-text-opacity;
            color: var(--contentMeta-text-color);
        }
    }

    // Modifier used when component is on a dark background
    &--dark-bg {
        #{$this}__item {
            &::before {
                opacity: $meta-text-opacity-dark-bg;
            }

            &--text {
                opacity: $meta-text-opacity-dark-bg;
            }
        }
    }

    // Modifier used to force component to render in small format across all screen sizes
    &--small {
        @include small-version(
            $mobile-item-gap,
            $bullet-width,
            $mobile-row-height
        );
    }

    @media (max-width: $break-phablet) {
        @include small-version(
            $mobile-item-gap,
            $bullet-width,
            $mobile-row-height
        );
    }
}
