// Styles for: Font imports
// Notes: Font import

// fonts marked 'variable weight font' should be able to cover all weights using the one font file

@font-face {
    font-family: Anton;
    src: url('../fonts/anton.eot');
    src: url('../fonts/anton.eot?#iefix') format('embedded-opentype'),
        url('../fonts/anton.woff2') format('woff2'),
        url('../fonts/anton.woff') format('woff'),
        url('../fonts/anton.ttf') format('truetype');
    font-weight: $regular;
}

@font-face {
    // variable weight font
    font-family: Archivo;
    src: url('../fonts/Archivo.ttf');
}

@font-face {
    font-family: Barlow;
    src: url('../fonts/Barlow-Regular.eot');
    src: url('../fonts/Barlow-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Barlow-Regular.woff2') format('woff2'),
        url('../fonts/Barlow-Regular.woff') format('woff'),
        url('../fonts/Barlow-Regular.ttf') format('truetype');
    font-weight: $regular;
}

@font-face {
    font-family: Barlow;
    src: url('../fonts/Barlow-ExtraBold.ttf');
    font-weight: $extrabold;
}

@font-face {
    font-family: Barlow;
    src: url('../fonts/Barlow-Bold.ttf');
    font-weight: $bold;
}

@font-face {
    font-family: Barlow;
    src: url('../fonts/Barlow-Black.ttf');
    font-weight: $fw-black;
}

@font-face {
    font-family: Barlow;
    src: url('../fonts/Barlow-Light.ttf');
    font-weight: $light;
}

@font-face {
    // variable weight font
    font-family: Chivo;
    src: url('../fonts/Chivo.ttf');
}

@font-face {
    // variable weight font
    font-family: Epilogue;
    src: url('../fonts/Epilogue.ttf');
}

@font-face {
    // variable weight font
    font-family: Inter;
    src: url('../fonts/Inter.ttf');
}

@font-face {
    // variable weight font
    font-family: Inter Tight;
    src: url('../fonts/InterTight.ttf');
}

@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Regular.eot');
    src: url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Regular.woff2') format('woff2'),
        url('../fonts/Lato-Regular.woff') format('woff'),
        url('../fonts/Lato-Regular.ttf') format('truetype');
    font-weight: $regular;
}

@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Black.ttf');
    font-weight: $fw-black;
}

@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Bold.ttf');
    font-weight: $bold;
}

@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Light.ttf');
    font-weight: $light;
}

@font-face {
    // variable weight font
    font-family: Lora;
    src: url('../fonts/Lora.ttf');
}

@font-face {
    // variable weight font
    font-family: Manrope;
    src: url('../fonts/Manrope.ttf');
}

@font-face {
    // variable weight font
    font-family: Montserrat;
    src: url('../fonts/Montserrat.ttf');
}

@font-face {
    // variable weight font
    font-family: Noto Sans;
    src: url('../fonts/NotoSans.ttf');
}

@font-face {
    // variable weight font
    font-family: Open Sans;
    src: url('../fonts/OpenSans.ttf');
}

@font-face {
    // variable weight font
    font-family: Oswald;
    src: url('../fonts/Oswald.ttf');
}

@font-face {
    font-family: Poppins;
    src: url('../fonts/Poppins-Regular.eot');
    src: url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: $regular;
}

@font-face {
    font-family: Poppins;
    src: url('../fonts/Poppins-Black.ttf');
    font-weight: $fw-black;
}

@font-face {
    font-family: Poppins;
    src: url('../fonts/Poppins-Bold.ttf');
    font-weight: $bold;
}

@font-face {
    font-family: Poppins;
    src: url('../fonts/Poppins-ExtraBold.ttf');
    font-weight: $extrabold;
}

@font-face {
    font-family: Poppins;
    src: url('../fonts/Poppins-Light.ttf');
    font-weight: $light;
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: $regular;
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/Roboto-Light.ttf');
    font-weight: $light;
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/Roboto-Bold.ttf');
    font-weight: $bold;
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/Roboto-Black.ttf');
    font-weight: $fw-black;
}

@font-face {
    // variable weight font
    font-family: Sora;
    src: url('../fonts/Sora.ttf');
}

@font-face {
    // variable weight font
    font-family: Source Sans;
    src: url('../fonts/SourceSans3.ttf');
}

@font-face {
    font-family: Space Grotesk;
    src: url('../fonts/SpaceGrotesk-Regular.ttf');
    font-weight: $regular;
}

@font-face {
    font-family: Space Grotesk;
    src: url('../fonts/SpaceGrotesk-Bold.ttf');
    font-weight: $bold;
}

@font-face {
    font-family: Space Grotesk;
    src: url('../fonts/SpaceGrotesk-Light.ttf');
    font-weight: $light;
}

@font-face {
    font-family: Space Grotesk;
    src: url('../fonts/SpaceGrotesk-SemiBold.ttf');
    font-weight: $semibold;
}

@font-face {
    // variable weight font
    font-family: Urbanist;
    src: url('../fonts/Urbanist.ttf');
}
