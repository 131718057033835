// Styles for: "Football: Player List"

.w-football-player-list {
    $this: &;
    margin: var(--g-widget-margin);
    margin-top: var(--card-gutter);

    &__position-header {
        color: var(--c-color-black);
        @include heading-M;
        margin-bottom: var(--card-gutter);
        text-transform: uppercase;
    }

    &__list-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: var(--card-gutter);
        margin-bottom: var(--card-gutter);

        > .w-football-player-card {
            width: calc(100% / 4 - 1.8rem);
        }
    }

    @media (max-width: $break-desktop) {
        &__list-wrapper {

            > .w-football-player-card {
                width: calc(100% / 3 - 1.6rem);
            }
        }
    }

    @media (max-width: $break-tablet) {
        &__list-wrapper {

            > .w-football-player-card {
                width: calc(100% / 2 - (var(--card-gutter) / 2));
            }
        }
    }

    @media (max-width: $break-phablet) {
        &__position-header {
            @include heading-L;
            margin-bottom: .8rem;
        }
        &__list-wrapper {

            > .w-football-player-card {
                width: calc(100% / 2 - .6rem);
            }
        }
    }
}
